import React, { Fragment, useState, Component } from "react";
import {
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn,
  MDBIcon,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput
} from "mdbreact";
import { updateContactApi } from "../../../../Api/contactApi";

import { successToast, errorToast } from "../../../../utils/toast";

export default class ContactModal extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      location: null,
      company_name: null,
      website_url: null,
      telephone_number: null,
      cell_number: null,
      mapUrl: null,
      fb_url: null,
      insta_url: null,
      youtube_url: null,
      twitter_url: null,
      linked_in_url: null,
      rowData: this.props.rowData
    };
    this.handleInput = this.handleInput.bind(this);
    this.updateContact = this.updateContact.bind(this);
  }

  handleInput = (e: any) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  updateContact = (id: string) => {
    var regex = /https.*?"/;
    const location =
      this.state.location == null
        ? this.props.rowData.location
        : this.state.location;
    const company_name =
      this.state.company_name == null
        ? this.props.rowData.company_name
        : this.state.company_name;
    const mapUrl =
      this.state.mapUrl == null
        ? regex.exec(this.props.rowData.mapUrl)
        : regex.exec(this.state.mapUrl);
    const telephone_number =
      this.state.telephone_number == null
        ? this.props.rowData.telephone_number
        : this.state.telephone_number;
    const cell_number =
      this.state.cell_number == null
        ? this.props.rowData.cell_number
        : this.state.cell_number;
    const website_url =
      this.state.website_url == null
        ? this.props.rowData.website_url
        : this.state.website_url;
    const fb_url =
      this.state.fb_url == null ? this.props.rowData.fb_url : this.state.fb_url;
    const insta_url =
      this.state.insta_url == null
        ? this.props.rowData.insta_url
        : this.state.insta_url;
    const youtube_url =
      this.state.youtube_url == null
        ? this.props.rowData.youtube_url
        : this.state.youtube_url;
    const twitter_url =
        this.state.twitter_url == null
          ? this.props.rowData.twitter_url
          : this.state.twitter_url;
    const linked_in_url =
      this.state.linked_in_url == null
        ? this.props.rowData.linked_in_url
        : this.state.linked_in_url;
    const form = new FormData();
    form.append("location", location);
    form.append("company_name", company_name);
    form.append("website_url", website_url);
    form.append("telephone_number", telephone_number);
    form.append("cell_number", cell_number);
    form.append("mapUrl", mapUrl != null ? mapUrl[0] : "");
    form.append("fb_url", fb_url);
    form.append("insta_url", insta_url);
    form.append("youtube_url", youtube_url);
    form.append("twitter_url", twitter_url);
    form.append("linked_in_url", linked_in_url);
    debugger;
    updateContactApi(id, form)
      .then((res: any) => {
        successToast("Company Contact Updated Sucessfully");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err: any) => {
        errorToast("Company Contact Updated On Error");
      });
  };

  render() {
    const openStatus = this.props.open;
    const toggle = this.props.toogle;
    const rowData = this.props.rowData;
    const company = this.props.company;
    return (
      <Fragment>
        <br />

        <MDBModal isOpen={openStatus} toggle={toggle} size="lg">
          <MDBModalHeader toggle={toggle}>Add new contact </MDBModalHeader>
          <MDBModalBody>
            <MDBContainer className="company_name">
              <MDBRow>
                <MDBCol md="12">
                  <MDBInput
                    size="sm"
                    icon="building"
                    label="Company Name"
                    outline
                    disabled
                    name="company_name"
                    valueDefault={company && company.name}
                    onInput={this.handleInput}
                  />
                </MDBCol>
              </MDBRow>
            </MDBContainer>

            <MDBContainer className="location_website">
              <MDBRow>
                <MDBCol md="6" className="location">
                  <MDBInput
                    size="sm"
                    icon="location-arrow"
                    label="Location"
                    outline
                    name="location"
                    valueDefault={rowData && rowData.location}
                    onInput={this.handleInput}
                  />
                </MDBCol>
                <MDBCol md="6" className="website">
                  <MDBInput
                    size="sm"
                    icon="globe"
                    label="Website Url"
                    type=""
                    outline
                    name="website_url"
                    onInput={this.handleInput}
                    valueDefault={rowData && rowData.website_url}
                  />
                </MDBCol>
              </MDBRow>
            </MDBContainer>

            <MDBContainer className="cell_telephone">
              <MDBRow>
                <MDBCol md="6" className="telephone">
                  <MDBInput
                    size="sm"
                    icon="phone-alt"
                    label="Telephone Number"
                    outline
                    type="number"
                    name="telephone_number"
                    onInput={this.handleInput}
                    valueDefault={rowData && rowData.telephone_number}
                  />
                </MDBCol>
                <MDBCol md="6" className="cell">
                  <MDBInput
                    size="sm"
                    icon="mobile-alt"
                    label="Cell Number"
                    outline
                    type="number"
                    name="cell_number"
                    onInput={this.handleInput}
                    valueDefault={rowData && rowData.cell_number}
                  />
                </MDBCol>
              </MDBRow>
            </MDBContainer>

            <MDBContainer className="mapUrl">
              <MDBRow>
                <MDBCol md="12" className="url">
                  <MDBInput
                    size="sm"
                    icon="map-marker-alt"
                    label="Location Map Url"
                    outline
                    name="mapUrl"
                    onInput={this.handleInput}
                    valueDefault={rowData && rowData.mapUrl}
                  />
                </MDBCol>
              </MDBRow>
            </MDBContainer>

            <MDBContainer className="social_contact">
              <MDBRow>
                <MDBCol md="4">
                  <MDBInput
                    size="sm"
                    iconBrand
                    icon="facebook-f"
                    label="Facebook Url"
                    outline
                    name="fb_url"
                    onInput={this.handleInput}
                    valueDefault={rowData && rowData.fb_url}
                  />
                </MDBCol>
                <MDBCol md="4">
                  <MDBInput
                    size="sm"
                    iconBrand
                    icon="instagram"
                    l
                    label="Instagram Url"
                    outline
                    name="insta_url"
                    onInput={this.handleInput}
                    valueDefault={rowData && rowData.insta_url}
                  />
                </MDBCol>

                <MDBCol md="4">
                  <MDBInput
                    size="sm"
                    iconBrand
                    icon="youtube"
                    label="Youtube Url"
                    outline
                    name="youtube_url"
                    onInput={this.handleInput}
                    valueDefault={rowData && rowData.youtube_url}
                  />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
            <MDBContainer className="social_contact">
              <MDBRow>
                <MDBCol md="4">
                  <MDBInput
                    size="sm"
                    iconBrand
                    icon="twitter"
                    label="Twitter Url"
                    outline
                    name="twitter_url"
                    onInput={this.handleInput}
                    valueDefault={rowData && rowData.twitter_url}
                  />
                </MDBCol>
                <MDBCol md="4">
                  <MDBInput
                    size="sm"
                    iconBrand
                    icon="linkedin"
                    label="LinkedIn Url"
                    outline
                    name="linked_in_url"
                    onInput={this.handleInput}
                    valueDefault={rowData && rowData.linked_in_url}
                  />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              color="danger"
              onClick={() => {
                toggle();
              }}
            >
              Close
            </MDBBtn>
            {this.props.edit ? (
              <MDBBtn
                color="primary"
                outline
                type="submit"
                className="w-30"
                onClick={() => {
                  toggle();
                  this.updateContact(rowData.id);
                }}
              >
                Update
                <MDBIcon far icon="plus-square" className="ml-2" />
              </MDBBtn>
            ) : (
              <MDBBtn color="primary" outline type="submit" className="w-30">
                Add
                <MDBIcon far icon="plus-square" className="ml-2" />
              </MDBBtn>
            )}
          </MDBModalFooter>
        </MDBModal>
      </Fragment>
    );
  }
}
