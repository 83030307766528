import axios from '../Axios/index';

export const postNewsApi = async(data: any) => {
    return await axios.request({
        url: `/api/news`,
        method: 'post',
        data: data,
        headers: { 'Content-Type': 'multipart/form-data' }
    }).then((res: any) => {
        return res.data
    })
}

export const getAllNewsApi = async () => {
    return await axios.request({
        url: `/api/news`,
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    }).then((res: any) => {
        return res.data
    })
}
export const getAllNewssByCompanyApi = async (company_id: String) => {
    return await axios.request({
        url: `/api/news/company/${company_id}`,
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    }).then((res: any) => {
        return res.data
    })
}
export const updateNewsApi = async (id: String, data: any) => {

    return await axios.request({
        url: `/api/news/${id}`,
        method: 'post',
        data: data,
        headers: {'Content-Type': 'multipart/form-data'}
    }).then((res: any) => {
        return res.data
    })

}

export const getNewsApi = async(id: any) => {
    return await axios.request({
        url: `/api/news/${id}`,
        method: 'get',
        headers: { 'Content-Type': 'application/json' }
    }).then((res: any) => {
        return res.data
    })

}

export const deleteNewsApi = async (news_id: any) => {
    return await axios.request({
        url: `/api/news/${news_id}`,
        method: 'delete'
    }).then((res: any) => {
        return res.data
    })

}