import React, { useState } from 'react';
import MaterialTable, { Column } from 'material-table';
import { Button } from '@material-ui/core';
import WorkModal from '../work-image-modal';
import WorkGalleryModal from '../work-gallery-modal';
import TestTable from "../../../../homepage/home-data-table"
import { config } from '../../../../../../config/common';


export default function MaterialTableDemo(props : any) {

  const [addStatus, setAddStatus] = React.useState(false);

  

  const toggleAddStatus  = () => {
    setAddStatus(!addStatus);
  }
  const columns = props.columns;
  const rows = props.rows;

  const [editStatus, setEditStatus] = React.useState(false);

  const toggleEditStatus  = () => {
    setEditStatus(!editStatus);
  }

  const [galleryStatus, setGalleryStatus] = React.useState(false);
  const toggleGalleryStatus  = () => {
    setGalleryStatus(!galleryStatus);
  }

  const [rowData, setRowData] = React.useState<any>();


  const [state, setState] = React.useState<any>({
    columns: props.columns,
    data: props.rows,
  });
  
  const {company, deleteWork, works, getWorkDetail, workImages, workCategories} = props;
debugger;
  return (
    
    <div>
     
    <MaterialTable
      title="Work Image List"
      // columns={state.columns}
      columns={[
        {
            title: "Image",
            field: "src",
            filtering: false,
            render: (rowData: any) => (
                rowData.thumbnail != null 
                ? <img
                    src={rowData.thumbnail}
                    alt={`${rowData.heading} image`}
                    style={{ width: 60}}
                />
                : "No Image"
                
                
                
            )
        },
        ...state.columns,
        {
          title: 'Work Category',
          field: 'work_categories_title'
          
        },
    ]}
      data={state.data}
      actions={[
        {
          icon: 'add',
          tooltip: 'Add',
          isFreeAction: true,
          onClick: (event) => {
            setAddStatus(true);
            // setGalleryStatus(true);
          }
        },
        rowData => ({
          icon: 'delete',
          tooltip: 'Delete',
          onClick: (event, rowData) =>{
            deleteWork(rowData)
          },
          disabled: false
        }),
        rowData => ({
          icon: 'edit',
          tooltip: 'Edit',
          onClick: (event, rowData) => {
            setRowData(rowData);
            setEditStatus(true);

          },
          disabled: false
        })
      ]}
      options={{
        actionsColumnIndex: -1,
        exportButton: true,
        filtering: true
      }}
      //https://material-table.com/#/docs/features/selection
    //   detailPanel={[
    //     {
    //       tooltip: 'Show Name',
    //       render: (rowData: any) => {
    //         return (
    //           <div
    //             style={{
    //               fontSize: 100,
    //               textAlign: 'center',
    //               color: 'white',
    //               backgroundColor: '#43A047',
    //             }}
    //           >
    //             <TestTable/>
    //           </div>
    //         )
    //       },
    //     }
    // ]}
    />
    {addStatus ? <div>  <WorkModal open={addStatus} toogle={toggleAddStatus} company={company} works={works}/></div>
    : null}
    {editStatus ? <WorkModal open={editStatus} toogle={toggleEditStatus} company={company} rowData={rowData} edit={true} works={works}/>
    : null}
    {galleryStatus ? <WorkGalleryModal open={galleryStatus} toogle={toggleGalleryStatus} rowData={rowData} works={works} workImages={workImages}/>
    : null}

    
    </div>
  );
}