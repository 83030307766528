import axios from '../Axios/index';

export const postWorkApi= async(data: any) => {
    return await axios.request({
        url: `api/work`,
        method: 'post',
        data: data,
        headers: { 'Content-Type': 'multipart/form-data' }
    }).then((res: any) => {
        return res.data
    })
}

export const getAllWorkApi = async () => {
    return await axios.request({
        url: `/api/work`,
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    }).then((res: any) => {
        return res.data
    })
}

export const getAllWorkByCompanyApi = async (company_id: String) => {
    return await axios.request({
        url: `/api/work/company/${company_id}`,
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    }).then((res: any) => {
        return res.data
    })
}

//Work Image
export const getAllWorkImagesByCompanyApi = async (company_id: String) => {
    return await axios.request({
        url: `/api/work/company/${company_id}/images`,
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    }).then((res: any) => {
        return res.data
    })
}

export const getWorkDetailsApi = async (work_id: String) => {
    return await axios.request({
        url: `/api/work/${work_id}`,
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    }).then((res: any) => {
        return res.data
    })
}

export const updateWorkApi = async (id: String, data: any) => {

    return await axios.request({
        url: `/api/work/${id}`,
        method: 'post',
        data: data,
        headers: {'Content-Type': 'application/json'}
    }).then((res: any) => {
        return res.data
    })

}

export const patchWorkImageApi= async(work_id: any, data: any) => {
    return await axios.request({
        url: `api/image/work/${work_id}`,
        method: 'post',
        data: data,
        headers: { 'Content-Type': 'multipart/form-data' }
    }).then((res: any) => {
        return res.data
    })
}

export const updateWorkImageApi= async(image_id: any, data: any) => {
    return await axios.request({
        url: `api/image/work/image/${image_id}`,
        method: 'post',
        data: data,
        headers: { 'Content-Type': 'multipart/form-data' }
    }).then((res: any) => {
        return res.data
    })
}

export const deleteWorkApi= async (work_id: any) => {
    return await axios.request({
        url: `/api/work/${work_id}`,
        method: 'delete'
    }).then((res: any) => {
        return res.data
    })

}

export const deleteWorkImageApi= async (image_id: any) => {
    return await axios.request({
        url: `/api/image/${image_id}`,
        method: 'delete',
        headers: {'Content-Type': 'application/json'}
    }).then((res: any) => {
        return res.data
    })

}

