import axios from '../Axios/index';

export const postCareerApi = async(data: any) => {
    return await axios.request({
        url: `/api/career`,
        method: 'post',
        data: data,
        headers: { 'Content-Type': 'multipart/form-data' }
    }).then((res: any) => {
        return res.data
    })
}

export const getAllCareersApi = async () => {
    return await axios.request({
        url: `/api/career`,
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    }).then((res: any) => {
        return res.data
    })
}
export const getAllCareersByCompanyApi = async (company_id: String) => {
    return await axios.request({
        url: `/api/career/company/${company_id}`,
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    }).then((res: any) => {
        return res.data
    })
}
export const updateCareerApi = async (id: String, data: any) => {

    return await axios.request({
        url: `/api/career/${id}`,
        method: 'post',
        data: data,
        headers: {'Content-Type': 'multipart/form-data'}
    }).then((res: any) => {
        return res.data
    })

}

export const getCareerApi = async(id: any) => {
    return await axios.request({
        url: `/api/career/${id}`,
        method: 'get',
        headers: { 'Content-Type': 'application/json' }
    }).then((res: any) => {
        return res.data
    })

}

export const deleteCareerApi = async (careerId: any) => {
    return await axios.request({
        url: `/api/career/${careerId}`,
        method: 'delete'
    }).then((res: any) => {
        return res.data
    })

}