import React, { Component } from "react";
import { MDBRow, MDBCol, MDBBtn, MDBIcon } from "mdbreact";
import {
  postBanner,
  updateCompanyApi,
  postCompany,
  getCompany
} from "../../../../Api/api";
import SpinnerPage from "../../../pages/SpinnerPage";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./index.scss";
import { successToast, errorToast } from "../../../../utils/toast";
import { MDBContainer } from "mdbreact";
import MyEditor from "../../../editor/ckeditor";
import { postAboutApi, updateAboutApi } from "../../../../Api/aboutApi";
import htmlToDraft from "html-to-draftjs";
import { postMediaApi } from "../../../../Api/mediaApi";
import { config } from "../../../../config/common";

class AboutIntroductionPage extends Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      company: {
        id: "",
        name: "",
        description: "",
        banner_caption: "",
        baImage: "",
        image: undefined,
        category: "",
        categories: [],
        data: null
      },
      images: [],
      loading: true,
      editorState: EditorState.createEmpty()
    };
  }

  componentDidMount() {
    if (this.state.company.data == null && this.props.about) {
      this.changeState(this.props.about.description);
    }
  }

  componentDidUpdate(prevprops: any) {
    if (
      this.props.about &&
      (prevprops.about
        ? this.props.about.description != prevprops.about.description
        : true)
    ) {
      this.changeState(this.props.about.description);
    }
  }

  onChangeEditor = (data: any) => {
    this.setState({
      data: data
    });
  };

  getFileBase64 = (file: any, callback: any) => {
    var reader = new FileReader();

    reader.onload = e => {
      this.setState({
        baImage: reader.result as string,
        image: file
      });
    };
    reader.readAsBinaryString(file);

    // Since FileReader is asynchronous,
    // we need to pass data back.
    // reader.onload = () => callback(reader.result);
    // // TODO: catch an error
    // reader.onerror = error => {};

    const form = new FormData();
    form.append("image", file);

    postMediaApi(this.props.companyId, form)
      .then((res: any) => {
        callback(config().rest_url + "/" + res);
      })
      .catch((res: any) => {
        console.log("error");
      });
  };

  imageUploadCallback = (file: any) =>
    new Promise((resolve, reject) =>
      this.getFileBase64(file, (data: any) => resolve({ data: { link: data } }))
    );

  changeState(value: any) {
    const contentBlock = htmlToDraft(value);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      this.onEditorStateChange(editorState);
    }
  }

  onEditorStateChange: any = (editorState: EditorState) => {
    this.setState({
      editorState
    });
  };

  updateAbout = (company_id: any) => {
    // const message = this.checkHomePageForm();
    // if(message != null){
    //     return alert(message);
    // }

    const description = draftToHtml(
      convertToRaw(this.state.editorState.getCurrentContent())
  );
    const src = this.props.about && this.props.about.src;
    const aboutId = this.props.about && this.props.about.id;
    const form = new FormData();
    form.append("company_id", company_id);
    form.append("description", description);
    form.append("src", src);

    updateAboutApi(aboutId, form)
      .then((res: any) => {
        successToast("Company About Page updated Sucessfully");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err: any) => {
        errorToast("Company About Page update On Error");
      });
  };

  render() {
    const companyId = this.props.companyId;
    const description =
      this.state.data == null && this.props.about
        ? this.props.about.description
        : this.state.data;
    return this.props.loading ? (
      <SpinnerPage />
    ) : (
      <MDBRow>
        <MDBCol>
          <MDBContainer className="content_label">
            <MDBRow>
              <MDBCol lg="12">Content(s)</MDBCol>
            </MDBRow>
          </MDBContainer>
          <MDBContainer className="about_intro_editor">
            <Editor
              editorState={this.state.editorState}
              wrapperClassName="demo-wrapper"
              editorClassName="form-control"
              toolbar={{
                // image: { previewImage: true},
                image: {
                  urlEnabled: true,
                  uploadEnabled: true,
                  alignmentEnabled: true, // Whether to display the arrange button is equivalent to text-align
                  uploadCallback: this.imageUploadCallback,
                  previewImage: true,
                  // inputAccept: 'image/*',
                  alt: { present: true, mandatory: false }
                }
              }}
              onEditorStateChange={this.onEditorStateChange}
            />
          </MDBContainer>
          <MDBContainer className="text-left mt-4">
            <MDBBtn
              color="primary"
              outline
              type="submit"
              className="w-10"
              onClick={() => this.updateAbout(companyId)}
            >
              Save
              <MDBIcon far icon="save" className="ml-2" />
            </MDBBtn>
          </MDBContainer>
          <br />
        </MDBCol>
      </MDBRow>
    );
  }
}

export default AboutIntroductionPage;
