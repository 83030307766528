import React, { Context, useContext } from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { User, Token } from 'auth0';
import credentials from '../config/credentials.json'
import axios from 'axios';
import { config } from '../config/common';

export type AuthState = {
    auth0?: Auth0Client,

    isAuthenticated: boolean,
    user?: User,
    token?: Token

    loading: boolean,
    popupOpen: boolean

    loginWithPopup?: (params:any) => void,
    handleRedirectCallback?: (state: any) => void,
    getIdTokenClaims?: (p: any) => any,
    loginWithRedirect?: (p: any) => any,
    getTokenSilently?: (p: any) => any,
    getTokenWithPopup?: (p: any) => any,
    logout?: (p: any) => any
};

const DEFAULT_REDIRECT_CALLBACK = (appState: any) =>
    window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext<AuthState>({
    isAuthenticated: false,
    user: undefined,
    loading: true,
    
    popupOpen: false,
});

export const useAuth0 = () => useContext(Auth0Context);

export class Auth extends React.Component<{}, AuthState> {
    tokenRenewalTimeout: any;

    cb?: (error: any, result?: any) => void;
    handleAuthRoute: any;

    constructor(props: {}) {
        super(props);

        this.state = {
            auth0: undefined,
            isAuthenticated: false,
            loading: false,
            popupOpen: false,
            user: {}
        }

        // this.initAuth0 = this.initAuth0.bind(this);
        this.loginWithPopup = this.loginWithPopup.bind(this);
        
    }


    async initAuth0() {
        const auth0 = await createAuth0Client(credentials);
        const isAuthenticated = await auth0.isAuthenticated();
        const onRedirectCallback = DEFAULT_REDIRECT_CALLBACK;

        if (window.location.search.includes("code=")) {
            const { appState } = await auth0.handleRedirectCallback();
            onRedirectCallback(appState);
        }

        const handleRedirectCallback = async () => {
            this.setState({
                loading: true
            });
            await auth0.handleRedirectCallback();
            const user = await auth0.getUser();
            const token = await auth0.getTokenSilently();
            this.setState({
                loading: false,
                isAuthenticated: true,
                user,
                token
            });
            // this.saveAuth();
        };

        if (isAuthenticated) {
            this.setState({
                user: await auth0.getUser()
            });

            axios.interceptors.request.use(async conf => {                
                if (conf.baseURL === config().rest_url) {
                    const token = await auth0.getTokenSilently();
                    if (token) {
                        conf.headers.Authorization = `Bearer ${token}`;
                        console.info("Added Token to axios interceptor.");
                    }
                }

                return conf;
            });

            axios.interceptors.response.use(response => response, error => {
                const status = error.response ? error.response.status : null;

                console.log(status);

                if (status === 401) {
                    this.setState({
                        isAuthenticated: false
                    });
                }

                return Promise.reject(error);
            })
        }

        this.setState({
            auth0,
            isAuthenticated,
            loading: false,
            loginWithPopup: this.loginWithPopup.bind(this),
            handleRedirectCallback: handleRedirectCallback.bind(this),
            
            getIdTokenClaims: auth0.getIdTokenClaims.bind(auth0),
            loginWithRedirect: auth0.loginWithRedirect.bind(auth0),
            getTokenSilently: auth0.getTokenSilently.bind(auth0),
            getTokenWithPopup: auth0.getTokenWithPopup.bind(auth0),
            logout: () => auth0.logout.bind(auth0)({
                returnTo: config().redirect_url
            })
        });
    }

    componentDidMount() {
        this.initAuth0();
    }

    async loginWithPopup(params:any = {}) {
        this.setState({
            popupOpen: true
        });
        try {
            const auth0Client = this.state.auth0 as Auth0Client;
            await auth0Client.loginWithPopup(params);
            const user = await auth0Client.getUser();
            const token = await auth0Client.getTokenSilently();

            axios.interceptors.request.use(async conf => {                
                if (conf.baseURL === config().rest_url) {
                    if (token) {
                        conf.headers.Authorization = `Bearer ${token}`;
                        console.info("Added Token to axios interceptor.");
                    }
                }

                return conf;
            });

            axios.interceptors.response.use(response => response, error => {
                const status = error.response ? error.response.status : null;

                console.log(status);

                if (status === 401) {
                    this.setState({
                        isAuthenticated: false
                    });
                }

                return Promise.reject(error);
            })

            this.setState({
                user: user,
                token: token,
                isAuthenticated: true
            });
            // this.saveAuth();
        } catch (error) {
            console.error(error);
        } finally {
            this.setState({
                popupOpen: false
            });
        }
    };

    render() {
        return (<>
        {this.state.auth0 && <Auth0Context.Provider
            value={{...this.state}}
        >
            {this.props.children}
        </Auth0Context.Provider>}
        </>)
    }
}
