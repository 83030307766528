import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import DashboardPage from '../pages/DashboardPage';
import DashboardWelcomePage from '../pages/DashboardWelcomePage'
import SettingPage from '../settings/SettingPage';

class Routes extends React.Component {
  render() {
    return (
      <Switch >
        <Route path='/' exact component={DashboardWelcomePage} />
        <Redirect exact from='/dashboard/company/:companyId' to="/dashboard/company/:companyId/homepage=1" /> 
        <Route path='/dashboard/company/:companyId/:tab=:tabIndex' component={DashboardPage} />
        <Route path='/dashboard/setting' exact component={SettingPage} />
        {/* <Route path='/profile' component={ProfilePage} />
        <Route path='/tables' component={TablesPage} />
        <Route path='/maps' component={MapsPage} /> */}
        {/* <Route path='/logout' component={MapsPage} />
        {/* <Route path='/404' component={NotFoundPage} />  */}
      </Switch>
    );
  }
}

export default Routes;
