import React, { Fragment, useState, Component } from "react";
import {
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn,
  MDBIcon,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput
} from "mdbreact";
import {
  postWorkCategoryApi,
  updateWorkCategoryApi
} from "../../../../../../Api/workCategory";
import { successToast, errorToast } from "../../../../../../utils/toast";
import CompanyContext from "../../../../homepage/context/companyContext";
import Select from "react-select";
import { postWorkApi, updateWorkApi, patchWorkImageApi, updateWorkImageApi } from "../../../../../../Api/work";
import Dropzone from "react-dropzone";
import { config } from "../../../../../../config/common";

export default class WorkModal extends Component<any, any> {
  static contextType = CompanyContext;

  constructor(props: any) {
    super(props);
    this.state = {
      title: null,
      description: null,
      work_id: null,
      rowData: this.props.rowData
    };
    this.handleInput = this.handleInput.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.addWork = this.addWork.bind(this);
    this.updataWork = this.updataWork.bind(this);
    this.createImage = this.createImage.bind(this);
    this.onDropFiles = this.onDropFiles.bind(this);
  }

  handleInput = (e: any) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleChange = (selectedOption: any) => {
    this.setState({
      work_id: selectedOption.value
    });
  };
  createImage(file: File) {
    let reader = new FileReader();

    reader.onload = e => {
      this.setState({
        baImage: reader.result as string,
        image: file
      });
    };
    reader.readAsBinaryString(file);
  }

  onDropFiles(files: File[]) {
    if (!files.length) return;

    this.createImage(files[0]);
  }

  updataWork = () => {
    let value = this.context;
    const title =
      this.state.title == null ? this.props.rowData.heading : this.state.title;
    const description =
      this.state.description == null
        ? this.props.rowData.description
        : this.state.description;
    const work_id =
      this.state.work_id == null
        ? this.props.rowData.work_id
        : this.state.work_id;
    const form = new FormData();
    debugger;
    form.append("heading", title);
    form.append("company_id", value);
    form.append("work_id", work_id);
    form.append("description", description);
    if(this.state.image != undefined){
      form.append("image", this.state.image as File);
    }
    
    updateWorkImageApi(this.props.rowData.id, form)
            .then((res: any) => {
                successToast('Company Work Image Updated Sucessfully');
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            })
            .catch((err: any) => {
                errorToast('Company Work Image Updated On Error');
            });
  };

  addWork = () => {
    let value = this.context;
    const title =
      this.state.title == null ? this.props.rowData.title : this.state.title;
    const description =
      this.state.description == null
        ? this.props.rowData.description
        : this.state.description;
    const work_id =
      this.state.work_id == null
        ? this.props.rowData.work_id
        : this.state.work_id;
    const form = new FormData();
    form.append("heading", title);
    form.append("company_id", value);
    form.append("work_id", work_id);
    form.append("description", description);
    form.append("image", this.state.image as File);
    patchWorkImageApi(work_id, form)
            .then((res: any) => {
                successToast('Company Work Image Uploaded Sucessfully');
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            })
            .catch((err: any) => {
                errorToast('Company Work Image Uploaded On Error');
            });
  };

  render() {
    const openStatus = this.props.open;
    const toggle = this.props.toogle;
    const rowData = this.props.rowData;
    const works = this.props.works;
    return (
      <Fragment>
        <br />

        <MDBModal isOpen={openStatus} toggle={toggle} size="lg">
          <MDBModalHeader toggle={toggle}>
            Add New Work Image{" "}
          </MDBModalHeader>
          <MDBModalBody>
                        <>
                        <MDBContainer className="work_image_title">
                            <MDBRow>
                                <MDBCol md="12" >
                                    <MDBInput
                                        size="sm"
                                        outline
                                        label="Title"
                                        name="title"
                                        valueDefault={rowData && rowData.heading}
                                        onInput={this.handleInput}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                        <MDBContainer className="work_category_select">
              <MDBRow>
                <MDBCol md="12">
                  <Select
                    options={works}
                    name="work_id"
                    placeholder="Select Work"
                    // defaultValue={workCategories[0]}
                    defaultValue={
                      rowData &&
                      works[
                        works.findIndex(
                          (x: any) => x.value === rowData.work_id
                        )
                      ]
                    }
                    onChange={this.handleChange}
                  />
                </MDBCol>
              </MDBRow>
            </MDBContainer>

                        <MDBContainer className="work_image_description">
                            <MDBRow>
                                <MDBCol md="12" className="url">
                                    <MDBInput
                                        size="sm"
                                        type="textarea"
                                        label="Description"
                                        outline
                                        name="description"
                                        valueDefault={rowData && rowData.description}
                                        onInput={this.handleInput}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>

                            {
                                !this.state.image ? (
                                    <>

                                        <Dropzone onDrop={this.onDropFiles} multiple={false}>
                                            {({ getRootProps, getInputProps }) => (
                                                <section>
                                                    <div {...getRootProps()} className="card card-body view file-upload">
                                                        <div className="card-text file-upload-message">
                                                            <i className="fas fa-cloud-upload-alt"></i>
                                                            <input {...getInputProps()} />
                                                            <p>Drag 'n' drop some files here, or click to select files</p>
                                                        </div>
                                                    </div>
                                                </section>
                                            )}
                                        </Dropzone>
                                        {rowData && rowData.src && (
                        <>
                          <img
                            src={ rowData.src}
                            alt="work image"
                            className="img-thumbnail"
                            style={{ width: "500px" }}
                          />
                        </>
                      )}
                                    </>
                                )
                            : (
                                    <>

                                        <MDBContainer>
                                            <img src={URL.createObjectURL(this.state.image)} alt="thumbnail" className="img-thumbnail" style={{objectFit: "cover", height: "10em" }}></img>
                                        </MDBContainer>
                                    </>
                                )
                            }
                            
                        </>
                    </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              color="danger"
              onClick={() => {
                toggle();
              }}
            >
              Close
            </MDBBtn>
            {this.props.edit ? (
              <MDBBtn
                color="primary"
                outline
                type="submit"
                className="w-30"
                onClick={() => {
                  toggle();
                  this.updataWork();
                }}
              >
                Update
                <MDBIcon far icon="plus-square" className="ml-2" />
              </MDBBtn>
            ) : (
              <MDBBtn
                color="primary"
                outline
                type="submit"
                className="w-30"
                onClick={() => {
                  toggle();
                  this.addWork();
                }}
              >
                Add
                <MDBIcon far icon="plus-square" className="ml-2" />
              </MDBBtn>
            )}
          </MDBModalFooter>
        </MDBModal>
      </Fragment>
    );
  }
}
