import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'bootstrap-css-only/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'mdbreact/dist/css/mdb.css';

import registerServiceWorker from './registerServiceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import credentials from './config/credentials.json';
import history from './utils/history';
import { Auth } from './auth/Auth';

const onRedirectCallback = (appState: any) => {
    history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
}

ReactDOM.render(
        <Auth>
            <Router>
                <App />
            </Router>
        </Auth>,
    document.getElementById('root')
);
registerServiceWorker();