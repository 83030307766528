import React, { useState } from 'react';
import MaterialTable, { Column } from 'material-table';
import { Button } from '@material-ui/core';
import WorkCategoryModal from '../work-category-modal';


export default function MaterialTableDemo(props : any) {

  const [addStatus, setAddStatus] = React.useState(false);

  const toggleAddStatus  = () => {
    setAddStatus(!addStatus);
  }
  const columns = props.columns;
  const rows = props.rows;

  const [editStatus, setEditStatus] = React.useState(false);

  const toggleEditStatus  = () => {
    setEditStatus(!editStatus);
  }

  const [rowData, setRowData] = React.useState<any>();


  const [state, setState] = React.useState<any>({
    columns: props.columns,
    data: props.rows,
  });
  
  const {company} = props;
  const {deleteWorkCategory}  = props;
  return (
    <div>
     
    <MaterialTable
      title="Work Category List"
      columns={state.columns}
      data={state.data}
      actions={[
        {
          icon: 'add',
          tooltip: 'Add',
          isFreeAction: true,
          onClick: (event) => {
            setAddStatus(true);
          }
        },
        rowData => ({
          icon: 'delete',
          tooltip: 'Delete',
          onClick: (event, rowData) =>{
            deleteWorkCategory(rowData)
          },
          disabled: false
        }),
        rowData => ({
          icon: 'edit',
          tooltip: 'Edit',
          onClick: (event, rowData) => {
            setRowData(rowData);
            setEditStatus(true);

          },
          disabled: false
        })
      ]}
      options={{
        actionsColumnIndex: -1,
        exportButton: true,
        filtering: true
      }}
    />
    {addStatus ? <div>  <WorkCategoryModal open={addStatus} toogle={toggleAddStatus} company={company}/></div>
    : null}
    {editStatus ? <WorkCategoryModal open={editStatus} toogle={toggleEditStatus} company={company} rowData={rowData} edit={true}/>
    : null}

    
    </div>
  );
}