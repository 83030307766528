interface QueryString<QValue> {
    [key: string]: QValue
  }
  
  
  export const getQueryStringParams = (query: string):QueryString<string> => {
    return query
      ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split('&')
        .reduce((params : any, param: any) => {
          let [key, value] = param.split('=');
          params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
          return params;
          }, {})
      : {}
  }