import React, { useState, Fragment } from 'react';
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/styles';

import IntroductionTeamPage from '../about/about-introduction';
import { useRouter } from '../../../hooks/router/userRouter';
import CareerTab from './careers';
import BlogTab from './blogs';
import NewsTab from './news';
// import AboutIntroductionPage from './about-introduction';
// import AboutTeamPage from './about-team';


const VerticalTabs = withStyles((theme: any) => ({
  flexContainer: {
    flexDirection: 'column'
  },
  indicator: {
    display: 'none',
  },
  root: {
    overflow: 'inherit !important ',
  },

}))(Tabs)

const MyTab = withStyles((theme: any) => ({
  selected: {
    color: 'blue',
    borderBottom: '2px solid blue',
    overflow: 'auto',
  }
}))(Tab);

function TabContainer(props: any) {
  return (
    <Typography component="div" style={{ paddingLeft: 24, width: "100%" }} >
      {props.children}
    </Typography>
  );
}

const ActivitesTabs = (props: any) => {

  const { query, push } = useRouter();
  const [activeIndex, setActiveIndex] = useState(query.tabIndex - 1);

  const handleTabIndexChange = (tabIndex: any) => {
    push(`/dashboard/company/${query.companyId}/activities=${tabIndex}`);

  }
  const handleChange = (_: any, activeIndex: any) => {
    setActiveIndex(activeIndex);
    handleTabIndexChange(activeIndex + 1);
  }

  const renderVerticalTabs = () => {
    return (
      <Fragment>
        
   <VerticalTabs
          value={activeIndex}
          onChange={handleChange}
        >
          <MyTab label='Blog(s)' />
          <MyTab label='Carreer(s)' />
          <MyTab label='News' />
        </VerticalTabs>

        {activeIndex === 0 && <TabContainer >
          <BlogTab companyId={query.companyId}/>
        </TabContainer>}
        {activeIndex === 1 && <TabContainer>
          <CareerTab companyId={query.companyId}/>
        </TabContainer>}
        {activeIndex === 2 && <TabContainer>
          <NewsTab companyId={query.companyId}/>
        </TabContainer >}
      </Fragment>
    ) 
  }

  return (
    <div
      style={{
        display: 'flex',
        width: "100%",
      }}
    >
    {renderVerticalTabs()}
  
    
    </div>
  )

}

export default ActivitesTabs;