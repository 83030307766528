import React, { Fragment, useState, Component } from "react";
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBBtn, MDBIcon, MDBContainer, MDBRow, MDBCol, MDBInput } from "mdbreact";
import { postWorkCategoryApi, updateWorkCategoryApi } from "../../../../../../Api/workCategory";
import { successToast, errorToast } from "../../../../../../utils/toast";
import CompanyContext from "../../../../homepage/context/companyContext";
import { postWorkApi, updateWorkApi, getWorkDetailsApi, patchWorkImageApi} from "../../../../../../Api/work";
import Dropzone from "react-dropzone";
import GalleryList from "./gallery-list";
import { config } from "../../../../../../config/common";

export default class WorkGalleryModal extends Component<any, any>{

    static contextType = CompanyContext;

    constructor(props: any) {
        super(props)
        this.state = {
            title: "",
            description: "",
            category_id: null,
            images: [],
            work : {},
            rowData: this.props.rowData,
        };
        this.handleInput = this.handleInput.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.patchImageToWork = this.patchImageToWork.bind(this);
        this.createImage = this.createImage.bind(this);
        this.onDropFiles = this.onDropFiles.bind(this);
        this.deleteWorkImage = this.deleteWorkImage.bind(this);
    }

    
    handleInput = (e: any) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleChange = (selectedOption: any) => {
        this.setState({
            category_id: selectedOption.value
        });
    };

    patchImageToWork = () => {
        let company_id = this.context;
        const work_id = this.state.id == null ? this.props.rowData.id : this.state.id;
        const work_image_title = this.state.title ;
        const work_image_description = this.state.description;
        const work_category_id = this.state.category_id == null ? this.props.rowData.category_id : this.state.category_id;
        const form = new FormData();
        form.append("company_id", company_id);
        form.append("work_category_id", work_category_id);
        form.append("image", this.state.image as File);
        form.append("heading", work_image_title);
        form.append("description", work_image_description);

        patchWorkImageApi(this.props.rowData.id, form)
            .then((res: any) => {
                successToast('Company Work Image Uploaded Sucessfully');
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            })
            .catch((err: any) => {
                errorToast('Company Work Image Uploaded On Error');
            });
    }

    createImage(file: File) {
        let reader = new FileReader();

        reader.onload = (e) => {
            this.setState({
                baImage: reader.result as string,
                image: file
            })
        };
        reader.readAsBinaryString(file);
    }

    onDropFiles(files: File[]) {
        if (!files.length)
            return;

        this.createImage(files[0]);
    }

    deleteWorkImage = (image: any) => {
        const images = this.state.images.filter((x: any) => config().rest_url + '/' + x.src !== image.src);
        this.setState({
            images: images
        });
        // this.clear();
        // this.imageToggle();
    }


    render() {
        const openStatus = this.props.open;
        const toggle = this.props.toogle;
        const rowData = this.props.rowData;
        const workCategories = this.props.workCategories;
        const { workImages } = this.props;
        return (
            <Fragment>
                <br />

                <MDBModal isOpen={openStatus} toggle={toggle} size="lg">
                    <MDBModalHeader toggle={toggle}>
                        <MDBBtn color="primary" outline type="submit" className='w-30' onClick={this.patchImageToWork}>
                            Upload
                            <MDBIcon far icon="plus-square" className="ml-2" />
                        </MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody>
                        <>
                        <MDBContainer className="work_image_title">
                            <MDBRow>
                                <MDBCol md="12" >
                                    <MDBInput
                                        size="sm"
                                        outline
                                        label="Title"
                                        name="title"
                                        onInput={this.handleInput}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                        <MDBContainer className="work_image_description">
                            <MDBRow>
                                <MDBCol md="12" className="url">
                                    <MDBInput
                                        size="sm"
                                        type="textarea"
                                        label="Description"
                                        outline
                                        name="description"
                                        onInput={this.handleInput}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>

                            {
                                !this.state.image && (
                                    <>

                                        <Dropzone onDrop={this.onDropFiles} multiple={false}>
                                            {({ getRootProps, getInputProps }) => (
                                                <section>
                                                    <div {...getRootProps()} className="card card-body view file-upload">
                                                        <div className="card-text file-upload-message">
                                                            <i className="fas fa-cloud-upload-alt"></i>
                                                            <input {...getInputProps()} />
                                                            <p>Drag 'n' drop some files here, or click to select files</p>
                                                        </div>
                                                    </div>
                                                </section>
                                            )}
                                        </Dropzone>
                                    </>
                                )
                            }{
                                this.state.image && (
                                    <>

                                        <MDBContainer>
                                            <img src={URL.createObjectURL(this.state.image)} alt="thumbnail" className="img-thumbnail" style={{objectFit: "cover", height: "10em" }}></img>
                                        </MDBContainer>
                                    </>
                                )
                            }
                            
                        </>
                    </MDBModalBody>
                </MDBModal>

            </Fragment>
        )
    }

}
