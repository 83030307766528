import axios from '../Axios/index';

export const postBlogApi = async(data: any) => {
    return await axios.request({
        url: `/api/blog`,
        method: 'post',
        data: data,
        headers: { 'Content-Type': 'multipart/form-data' }
    }).then((res: any) => {
        return res.data
    })
}

export const getAllBlogApi = async () => {
    return await axios.request({
        url: `/api/blog`,
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    }).then((res: any) => {
        return res.data
    })
}
export const getAllBlogsByCompanyApi = async (company_id: String) => {
    return await axios.request({
        url: `/api/blog/company/${company_id}`,
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    }).then((res: any) => {
        return res.data
    })
}
export const updateBlogApi = async (id: String, data: any) => {

    return await axios.request({
        url: `/api/blog/${id}`,
        method: 'post',
        data: data,
        headers: {'Content-Type': 'multipart/form-data'}
    }).then((res: any) => {
        return res.data
    })

}

export const getBlogApi = async(id: any) => {
    return await axios.request({
        url: `/api/blog/${id}`,
        method: 'get',
        headers: { 'Content-Type': 'application/json' }
    }).then((res: any) => {
        return res.data
    })

}

export const deleteBlogApi = async (blog_id: any) => {
    return await axios.request({
        url: `/api/blog/${blog_id}`,
        method: 'delete'
    }).then((res: any) => {
        return res.data
    })

}