let env = {
  'local': {
    
    // 'rest_url': 'http://localhost:8000',
    // 'redirect_url': 'http://localhost:3001',
    'rest_url': 'https://arc-api.scieverinc.com/',
    'redirect_url': 'https://arc-admin.scieverinc.com',
    // 'rest_url': 'https://arc.api.scieverinc.com/',
    // 'redirect_url': 'https://admin.arc.com.np',
    'title': 'local'
  },
};
export const config = () => {
  return env['local'] ;
};