import {
  MDBIcon,
  MDBBtn,
  MDBModalFooter,
  MDBModal,
  MDBModalHeader,
  MDBModalBody
} from "mdbreact";
import React, { Fragment } from "react";
import Gallery from "react-grid-gallery";

import "./index.scss";

import copy from "copy-to-clipboard";
import Dropzone from "react-dropzone";
import { updateAboutApi } from "../../../../Api/aboutApi";
import { errorToast, successToast } from "../../../../utils/toast";
import { MDBContainer } from "mdbreact";
import { MDBRow } from "mdbreact";
import { MDBCol } from "mdbreact";
import { config } from "../../../../config/common";

const pageSize = 12;

export default class AboutTeamPage extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      currentImage: 0,
      images: [],
      lowIndex: 0,
      maxIndex: 0,
      currentPage: 1
    };

    this.onCurrentImageChange = this.onCurrentImageChange.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
    this.copyToClipBoard = this.copyToClipBoard.bind(this);
    this._next = this._next.bind(this);
    this._prev = this._prev.bind(this);

    this.createImage = this.createImage.bind(this);
    this.onDropFiles = this.onDropFiles.bind(this);
    this.toggle = this.toggle.bind(this);

    this.clear = this.clear.bind(this);
    this.submitHandler = this.submitHandler.bind(this);

    this.renderUploadImage = this.renderUploadImage.bind(this);
    this.updateAbout = this.updateAbout.bind(this);
  }


  onCurrentImageChange(index: any) {
    this.setState({ currentImage: index });
  }

  deleteImage() {
    if (window.confirm(`Are you sure ?`)) {
      var images = this.state.images;
      const image = images[this.state.currentImage];
      var source = image.src;
      var data = { source: source };

      console.log("Image Deleted!");
    }
  }

  clear() {
    this.setState({ image: "" });
  }

  copyToClipBoard() {
    var images = this.state.images;
    const image = images[this.state.currentImage];
    var source = image.src;
    copy(source);
  }

  _next(highIndex: any) {
    this.setState({
      lowIndex: highIndex,
      currentPage: this.state.currentPage + 1
    });
  }

  _prev(lowIndex: any) {
    this.setState({
      lowIndex: lowIndex - 6,
      currentPage: this.state.currentPage - 1
    });
  }

  createImage(file: File) {
    let reader = new FileReader();

    reader.onload = e => {
      this.setState({
        baImage: reader.result as string,
        image: file
      });
    };
    reader.readAsBinaryString(file);
  }

  onDropFiles(files: File[]) {
    if (!files.length) return;

    this.createImage(files[0]);
  }

  toggle = () => {
    this.clear();
    this.setState({
      modal: !this.state.modal
    });
  };

  updateAbout = (company_id: any) => {
    const description = this.props.about && this.props.about.description;
    const src = this.props.about && this.props.about.src;
    const aboutId = this.props.about && this.props.about.id;
    const form = new FormData();
    form.append("company_id", company_id);
    form.append("description", description);
    form.append("src", src);
    form.append("image", this.state.image as File);
    updateAboutApi(aboutId, form)
      .then((res: any) => {
        successToast("Company About Page updated Sucessfully");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err: any) => {
        errorToast("Company About Page update On Error");
      });
  };

  submitHandler = (event: any) => {
    event.preventDefault();

    this.setState({ loading: true });
    const form = new FormData();

    form.append("company_Id", this.props.companyId);
    this.clear();
    this.toggle();
    // postMedia(this.props.companyId, form)
    //   .then((res: any) => {
    //     this.setState({ loading: false });
    //     successToast('Image uploaded Sucessfully');
    //     setTimeout(() => {
    //       window.location.reload();
    //     }, 2000);
    //   })
    //   .catch((res: any) => {
    //     errorToast('Error while uploading image : ' + res);
    //   });
  };
  renderUploadImage() {
    const companyId = this.props.companyId;
    
    return (
      <Fragment>
        {!this.state.image && (
          <>
            <Dropzone onDrop={this.onDropFiles} multiple={true}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div
                    {...getRootProps()}
                    className="card card-body view file-upload"
                  >
                    <div className="card-text file-upload-message">
                      <i className="fas fa-cloud-upload-alt"></i>
                      <input {...getInputProps()} />
                      <p>
                        Drag 'n' drop some files here, or click to select files
                      </p>
                    </div>
                  </div>
                </section>
              )}
            </Dropzone>
          </>
        )}
        {this.state.image && (
          <>
            <div>
              <img
                src={URL.createObjectURL(this.state.image)}
                alt="thumbnail"
                className="img-thumbnail"
                style={{ width: "500px" }}
              ></img>
            </div>

            <MDBBtn
              color="danger"
              outline
              type="submit"
              className="w-30"
              onClick={() => this.setState({ image: "" })}
            >
              Cancel
              <MDBIcon far icon="plus-square" className="ml-2" />
            </MDBBtn>

            <MDBBtn
              color="primary"
              outline
              type="submit"
              className="w-30"
              onClick={() => this.updateAbout(companyId)}
            >
              Upload
              <MDBIcon far icon="plus-square" className="ml-2" />
            </MDBBtn>
          </>
        )}
      </Fragment>
    );
  }

  render() {
      const about = this.props.about;
    const image = about ? [
        {
            src: config().rest_url + "/" + about.src,
            thumbnail: config().rest_url + "/" + about.thumbnail,
            thumbnailWidth: about.thumbnailWidth,
            thumbnailHeight: about.thumbnailHeight
        }
    ] : [];
    return (
      <>
        <MDBContainer className="image-uploader">
          <MDBRow>
            <MDBCol md="5">{this.renderUploadImage()}</MDBCol>
            <MDBCol md="7">
              <Gallery
                key={image}
                images={image}
                enableImageSelection={false}
                currentImageWillChange={this.onCurrentImageChange}
                customControls={[
                  <button
                    className="close_1x3s325"
                    style={{ color: "white" }}
                    key="deleteImage"
                    onClick={this.deleteImage}
                  >
                    <MDBIcon far icon="trash-alt" />
                  </button>,

                  <button
                    className="copy"
                    style={{ color: "white" }}
                    key="copyImage"
                    onClick={this.copyToClipBoard}
                  >
                    <MDBIcon far icon="copy" />
                  </button>
                ]}
              />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </>
    );
  }
}
