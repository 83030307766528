import React, { Component } from 'react';
import { MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarToggler, MDBCollapse, MDBNavItem, MDBNavLink, MDBIcon, MDBRow, MDBCol } from 'mdbreact';


class LogInPage extends Component<any, any>{



    state = {
        collapse: false,
        dropdownOpen: false
    }

    onClick = () => {
        this.setState({
            collapse: !this.state.collapse,
        });
    }

    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    render() {
        return (
            <React.Fragment>
      <div >
        <MDBRow className="bad-gateway-row">
          <MDBCol md="8">
            {/* <img alt="MDB React Logo" className="img-fluid" src={logo}/> */}
            <h4 className="h4-responsive mt-3 mb-2">Welcome to</h4>
            <h2> ARC Dashboard</h2>
            <button type="button" className="btn btn-info" onClick={this.props.login}>Login   <MDBIcon fas icon="sign-in-alt" className="ml-2" /></button>
          </MDBCol>
        </MDBRow>
      </div>
    </React.Fragment>

           
        );
    }
}

export default LogInPage;