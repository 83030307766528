import React, { useState, Fragment, useEffect } from 'react';
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/styles';

import { useRouter } from '../../../hooks/router/userRouter';
import WorkCategoryPage from './worktabs/workcategory';
import WorkPage from './worktabs/work';
import WorkImagePage from './worktabs/work_image';


const VerticalTabs = withStyles((theme: any) => ({
  flexContainer: {
    flexDirection: 'column'
  },
  indicator: {
    display: 'none',
  },
  root: {
    overflow: 'inherit !important ',
  },

}))(Tabs)

const MyTab = withStyles((theme: any) => ({
  selected: {
    color: 'blue',
    borderBottom: '2px solid blue',
    overflow: 'auto',
  }
}))(Tab);

function TabContainer(props: any) {
  return (
    <Typography component="div" style={{ paddingLeft: 24, width: "100%" }} >
      {props.children}
    </Typography>
  );
}



const WorkPageTabs = (props: any) => {

  const { query, push } = useRouter();
  const [activeIndex, setActiveIndex] = useState(query.tabIndex - 1);

  // const [workCategories, setWorkCategories] = useState([]);


  // useEffect( 
  //   () => {
  //     getAllWorkCategoryByCompanyApi(query.companyId)
  //           .then((res: any) => {
  //             setWorkCategories(res);
  //           })
  //           .catch((err: any) => {
  //             alert('Error to get work categories ' + err)
  //           });
  //   },query.companyId);

  const handleTabIndexChange = (tabIndex: any) => {
    push(`/dashboard/company/${query.companyId}/works=${tabIndex}`);

  }
  const handleChange = (_: any, activeIndex: any) => {
    setActiveIndex(activeIndex);
    handleTabIndexChange(activeIndex + 1);
  }

  const renderVerticalTabs = () => {
    return (
      <Fragment>
        
   <VerticalTabs
          value={activeIndex}
          onChange={handleChange}
        >
          <MyTab label='Work Category(s)' />
          <MyTab label='Work(s)' />
          <MyTab label='Work Image(s)' />
        </VerticalTabs>

        {activeIndex === 0 && <TabContainer >
          <WorkCategoryPage/>
        </TabContainer>}
        {activeIndex === 1 && <TabContainer>
          <WorkPage />
        </TabContainer>}
        {activeIndex === 2 && <TabContainer>
          <WorkImagePage />
        </TabContainer>}
      </Fragment>
    ) 
  }

  return (
    <div
      style={{
        display: 'flex',
        width: "100%",
      }}
    >
    {renderVerticalTabs()}
  
    
    </div>
  )

}

export default WorkPageTabs;