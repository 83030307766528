import React, { useState } from "react";
import MaterialTable, { Column } from "material-table";
import { Button } from "@material-ui/core";
import HomeModal from "../home-modal";
import { config } from "../../../../../config/common";

export default function MaterialTableDemo(props: any) {
  const [addStatus, setAddStatus] = React.useState(false);

  const toggleAddStatus = () => {
    setAddStatus(!addStatus);
  };
  const columns = props.columns;
  const rows = props.rows;

  const [editStatus, setEditStatus] = React.useState(false);

  const toggleEditStatus = () => {
    setEditStatus(!editStatus);
  };

  const [previewImage, setPreviewImage] = React.useState(false);

  const togglePreviewImage = () => {
    setPreviewImage(!previewImage);
  };

  const [rowData, setRowData] = React.useState<any>();

  const [state, setState] = React.useState<any>({
    columns: props.columns,
    data: props.rows
  });

  const clearState = () => {
    setAddStatus(false);
    setEditStatus(false);
    setPreviewImage(false);
  };

  const { companyId } = props;
  const { deleteCareer } = props;

  if (addStatus) {
    return (
      <HomeModal open={addStatus} toogle={toggleAddStatus} companyId={companyId} />
    );
  }
  if (editStatus) {
    return (
      <HomeModal
        open={editStatus}
        toogle={toggleEditStatus}
        companyId={companyId}
        rowData={rowData}
        edit={true}
      />
    );
  }
  if (previewImage) {
    return (
      <div>
        <button onClick={clearState}>Back</button>
        <img
          src={config().rest_url + "/" + rowData.src}
          alt="thumbnail"
          className="img-thumbnail"
          style={{ width: "500px" }}
        ></img>
      </div>
    );
  }
  return (
    <div>
      <MaterialTable
        title="Career List"
        // columns={state.columns}
        columns={[
          // {
          //     title: "Image",
          //     field: "src",
          //     filtering: false,
          //     render: (rowData: any) => (
          //         (rowData.src != null && rowData.tag === 'image') 
          //         ? <img
          //             src={config().rest_url+'/'+rowData.src}
          //             alt={`${rowData.title} image`}
          //             style={{ width: 60}}
          //         />
          //         : "No Image"
          //     )
          // },
          ...state.columns
      ]}
        data={state.data}
        actions={[
          {
            icon: "add",
            tooltip: "Add",
            isFreeAction: true,
            onClick: event => {
              setAddStatus(true);
            }
          },
          // (rowData: any) => ({
          //   icon: "camera",
          //   tooltip: (rowData.src && rowData.tag === 'image') ? "Preview Image" : "No Image",
          //   onClick: (event, rowData) => {
          //     setRowData(rowData);
          //     setPreviewImage(true);
          //   },
          //   disabled: (rowData.src && rowData.tag === 'image') ? false : true
          // }),
          rowData => ({
            icon: "delete",
            tooltip: "Delete",
            onClick: (event, rowData) => {
              deleteCareer(rowData);
            },
            disabled: false
          }),
          rowData => ({
            icon: "edit",
            tooltip: "Edit",
            onClick: (event, rowData) => {
              setRowData(rowData);
              setEditStatus(true);
            },
            disabled: false
          })
        ]}
        options={{
          actionsColumnIndex: -1,
          exportButton: true,
          filtering: true
        }}
      />
      {/* {addStatus ? <div>  <HomeModal open={addStatus} toogle={toggleAddStatus} companyId={companyId}/></div>
    : null}
    {editStatus ? <HomeModal open={editStatus} toogle={toggleEditStatus} companyId={companyId} rowData={rowData} edit={true}/>
    : null}

    { previewImage ? <img src={config().rest_url + '/' + rowData.image_url} alt="thumbnail" className="img-thumbnail" style={{ width: '500px' }}></img>
    : null} */}
    </div>
  );
}
