import React, { Component, Fragment } from "react";
import { MDBRow, MDBCol, MDBBtn, MDBIcon, MDBInput, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBDataTable } from 'mdbreact';
import { getAllWorkCategoryByCompanyApi, deleteWorkCategoryApi } from "../../../../../Api/workCategory";

import SpinnerPage from "../../../../pages/SpinnerPage";

import { successToast, errorToast } from '../../../../../utils/toast'

import CompanyContext from "../../../homepage/context/companyContext";

import WorkDataTable from "./work-data-table";

import { getAllWorkByCompanyApi, deleteWorkApi, getWorkDetailsApi } from "../../../../../Api/work";
import { MDBContainer } from "mdbreact";
import { config } from "../../../../../config/common";


interface WorkCategoryState {
  workCategory: WorkCategoryInterface,
  workCategories: [],
  workImages: [],
  works: [],
  modal: boolean,
  edit: boolean,

  optionActive: string,
  optionActiveId: string,
  loading: boolean
}


interface WorkCategoryInterface {
  id: string,
  title: string,
  description: string
}


export default class WorkPage extends Component<any, WorkCategoryState>{

  static contextType = CompanyContext;

  constructor(props: any) {
    super(props)
    this.state = {
      workCategory: {
        id: '',
        title: "",
        description: ""
      },
      workImages: [],
      workCategories: [],
      works: [],
      modal: false,
      edit: false,
      optionActive: '',
      optionActiveId: '',
      loading: false,
    };
    this.clear = this.clear.bind(this);
    this.deleteWork = this.deleteWork.bind(this);
    this.getWorkDetail =this.getWorkDetail.bind(this);
  }



  componentDidMount() {
    let value = this.context;
    this.setState({ loading: true });
    getAllWorkByCompanyApi(value)
      .then((res: any) => {
        this.setState({ works: res, loading: false });
        getAllWorkCategoryByCompanyApi(value)
          .then((res: any) => {
            this.setState({ workCategories: res, loading: false })
          })
          .catch((err: any) => {
            alert('Error to get work categories ' + err)
          });
      })
      .catch((err: any) => {
        alert('Error to get works ' + err)
      });

  }

  deleteWork = (rowdata: any) => {
    if (window.confirm("Are you sure you want to delete this work?")) {
      deleteWorkApi(rowdata.id)
        .then((res: any) => {
          successToast('Company Work Deleted Sucessfully');
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch((err: any) => {
          alert('Error to delete a company work ' + err)
        });
    }

  }

  getWorkDetail = (rowdata: any) => {
    getWorkDetailsApi(rowdata.id)
        .then((res: any) => {
          this.setState({
            workImages: res.images.map((image: any) => ({
              ...image,
              src: config().rest_url + '/' + image.src,
              thumbnail: config().rest_url + '/' + image.thumbnail,
              thumbnailWidth: Number.parseInt(image.thumbnailWidth),
              thumbnailHeight: Number.parseInt(image.thumbnailHeight),
            }))
          })
        })
        .catch((err: any) => {
          alert('Error to get work details ' + err)
        });

  }

  clear() {
    this.setState({
      workCategory: {
        ...this.state.workCategory,
        title: "",
        description: ""
      }


    });
  }




  render() {
    const data = {
      columns: [

        {
          title: 'Title',
          field: 'title',
        },
        // {
        //   title: 'Description',
        //   field: 'description',
        // },
        {
          title: 'Work Category',
          field: 'work_category_title'
        }
      ],
    }

    const workCategories = this.state.workCategories.map((workCategory: any) => ({
      label: workCategory.title,
      value: workCategory.id
    }));

    const company = this.props.company && this.props.company[0];
    
    return (


      this.state.loading
        ? <SpinnerPage />
        : <MDBContainer className="work-category-table">
            <WorkDataTable
              columns={data.columns}
              rows={this.state.works}
              workCategories={workCategories}
              company={company}
              deleteWork={this.deleteWork}
              getWorkDetail={this.getWorkDetail}
              workImages={this.state.workImages}
            />
        </MDBContainer>
    );
  };
}