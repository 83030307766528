import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';

import SideNavigation from "./components/navigation/sideNavigation";
import Routes from './components/routes/Routes';
import { Route } from 'react-router';
import LandingPage from './components/pages/landing-page/landingpage';
import LoginPage from './components/pages/loginpage';
import { Auth0Context } from './auth/Auth';
import { MDBIcon } from 'mdbreact';


class App extends Component {

  static contextType = Auth0Context;
  context!: React.ContextType<typeof Auth0Context>

  render() {

    const { isAuthenticated, loginWithPopup, getTokenSilently } = this.context;

    console.log(this.context);


    const login = () => {
      console.log(loginWithPopup);

      if (loginWithPopup) {
        loginWithPopup({})
      }
    }
    return (

      <div className="flexible-content">
        {!isAuthenticated ?
          <LoginPage login={login} />
          : 
          <div>
            <button type="button" className="btn btn-info" style={{ float: "right", margin: "1rem" }} onClick={this.context.logout}>Logout  <MDBIcon fas icon="sign-out-alt" className="ml-2" /></button>

            {/* <Route path='/' exact component={LandingPage} />
           */}
           <SideNavigation/>
            <main id="content" className="p-5">
              <Routes />
            </main>
          </div>
        }
      </div>

    );
  }
}

export default App;
