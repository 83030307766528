import React, { Component } from 'react';
// import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-react';

// import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
// import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
// import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
// import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
// import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
// import Heading from '@ckeditor/ckeditor5-heading/src/heading';
class MyEditor extends Component {
    // constructor(){
        state = {
            editor : ''
        }
    // }
    render() {
        const { uploadUrl, data, onChange } = this.props;
        // const toolbar = [  '|', 'heading', '|', 'bold', 'italic', 'link','bulletedList', 'numberedList', 'blockQuote','|', 'undo', 'redo' ,'|', ];
        const toolbar = ["undo", "redo", "bold", "italic", "blockQuote", "heading", "link", "numberedList", "bulletedList", "mediaEmbed", ]
        if(uploadUrl != null){
            toolbar.push('imageUpload')
        }
        return (
            <div className="editor">
                
                <CKEditor
                    editor={ ClassicEditor }
                    data={data}
                    config={
                        {
                            image: {
                                styles: [
                                    'alignLeft', 'alignCenter', 'alignRight','imageResize'
                                ],
                    
                                // You need to configure the image toolbar, too, so it shows the new style
                                // buttons as well as the resize buttons.
                                toolbar: [
                                    'imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight',
                                    '|',
                                    'imageTextAlternative',
                                ]
                            },
                            toolbar: toolbar,
                            // plugins: [ 'Essentials', 'Heading', 'Paragraph', 'Bold', 'Italic', 'BlockQuote', 'Alignment', 'List', 'Link'],
                            // removePlugins: ['Image', 'ImageCaption', 'ImageStyle', 'ImageToolbar', 'ImageUpload'],
                            ckfinder:{
                                options: { resourceType: 'Images' },
                                uploadUrl: {uploadUrl}
                                // 'http://localhost:8000/api/upload',
                                // openerMethod: 'popup',
                                // headers: {
                                //     // 'X-CSRF-TOKEN': 'CSFR-Token',
                                //     // Authorization: 'Bearer <JSON Web Token>',
                                //     // 'Content-Type': "application/json",
                                //     'Access-Control-Allow-Origin' : '*'
                                // }
                            }
                        }
                    }
                    onInit={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        // console.log( { event, editor, data } );
                        // this.setState({
                        //     editor : data
                        // })
                        onChange(data);
                    } }
                />
            </div>
        );
    }
}

export default MyEditor;