import React, { useState, Fragment, useEffect } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/styles";

import IntroductionTeamPage from "./aboutTabs/introduction-team";
import { useRouter } from "../../../hooks/router/userRouter";
import AboutIntroductionPage from "./about-introduction";
import AboutTeamPage from "./about-team";
import { getAllAboutsByCompanyApi } from "../../../Api/aboutApi";

const VerticalTabs = withStyles((theme: any) => ({
  flexContainer: {
    flexDirection: "column"
  },
  indicator: {
    display: "none"
  },
  root: {
    overflow: "inherit !important "
  }
}))(Tabs);

const MyTab = withStyles((theme: any) => ({
  selected: {
    color: "blue",
    borderBottom: "2px solid blue",
    overflow: "auto"
  }
}))(Tab);

function TabContainer(props: any) {
  return (
    <Typography component="div" style={{ paddingLeft: 24, width: "100%" }}>
      {props.children}
    </Typography>
  );
}

const AboutTabs = (props: any) => {
  const { query, push } = useRouter();
  const [activeIndex, setActiveIndex] = useState(query.tabIndex - 1);
  const [aboutData, setAboutData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await getAllAboutsByCompanyApi(query.companyId);
      setAboutData(result);
    };
    fetchData();
  }, []);
  const handleTabIndexChange = (tabIndex: any) => {
    push(`/dashboard/company/${query.companyId}/about=${tabIndex}`);
  };
  const handleChange = (_: any, activeIndex: any) => {
    setActiveIndex(activeIndex);
    handleTabIndexChange(activeIndex + 1);
  };

  const renderVerticalTabs = () => {
    return (
      <Fragment>
        <VerticalTabs value={activeIndex} onChange={handleChange}>
          <MyTab label="Introduction" />
          <MyTab label="Team(s)" />
        </VerticalTabs>

        {activeIndex === 0 && (
          <TabContainer>
            <AboutIntroductionPage companyId={query.companyId} about={aboutData && aboutData[0]}/>
          </TabContainer>
        )}
        {activeIndex === 1 && (
          <TabContainer>
            <AboutTeamPage companyId={query.companyId} about={aboutData && aboutData[0]}/>
          </TabContainer>
        )}
      </Fragment>
    );
  };
  return (
    <div
      style={{
        display: "flex",
        width: "100%"
      }}
    >
      {renderVerticalTabs()}
    </div>
  );
};

export default AboutTabs;
