import axios from '../Axios/index';

export const postMediaApi = async(companyId:string, data: any) => {
    return await axios.request({
        url: `/api/company/${companyId}/image`,
        method: 'post',
        data: data,
        headers: { 'Content-Type': 'multipart/form-data' }
    }).then((res: any) => {
        return res.data
    })
}

export const getAllCompanyMedia = (companyId: string) => {
    return axios.request({
        url: `/api/company/${companyId}/images`,
        method: "get",
        headers: { "Content-Type": "application/json" }
    });
};
export const getAllAboutsByCompanyApi = async (company_id: String) => {
    return await axios.request({
        url: `/api/about/company/${company_id}`,
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    }).then((res: any) => {
        return res.data
    })
}
export const updateAboutApi = async (id: String, data: any) => {

    return await axios.request({
        url: `/api/about/${id}`,
        method: 'post',
        data: data,
        headers: {'Content-Type': 'multipart/form-data'}
    }).then((res: any) => {
        return res.data
    })

}

export const getAboutApi = async(id: any) => {
    return await axios.request({
        url: `/api/about/${id}`,
        method: 'get',
        headers: { 'Content-Type': 'application/json' }
    }).then((res: any) => {
        return res.data
    })

}

export const deleteImageApi= async (image_id: any) => {
    return await axios.request({
        url: `/api/image/${image_id}`,
        method: 'delete',
        headers: {'Content-Type': 'application/json'}
    }).then((res: any) => {
        return res.data
    })

}