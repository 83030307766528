import React, { Fragment, useState, Component } from "react";
import {
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn,
  MDBIcon,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput
} from "mdbreact";
import { postNewsApi, updateNewsApi } from "../../../../../Api/newsApi";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import LightTooltip from "../../../../tooltip";
import { successToast, errorToast } from "../../../../../utils/toast";
import { config } from "../../../../../config/common";

import { FormControl, Form } from "react-bootstrap";

import { Tabs, Tab } from "react-bootstrap";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import { postMediaApi } from "../../../../../Api/mediaApi";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";

import "../../activities.scss";

export default class HomeModal extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      title: null,
      description: null,
      author: null,
      rowData: this.props.rowData,
      editorState: EditorState.createEmpty()
    };
    this.handleInput = this.handleInput.bind(this);
    this.addNews = this.addNews.bind(this);
    this.updateNews = this.updateNews.bind(this);

    this.onChangeEditor = this.onChangeEditor.bind(this);
    this.getFileBase64 = this.getFileBase64.bind(this);
    this.imageUploadCallback = this.imageUploadCallback.bind(this);

    this.checkHomePageForm = this.checkHomePageForm.bind(this);
  }


  

  handleInput = (e: any) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  onChangeEditor = (data: any) => {
    this.setState({
      data: data
    });
  };

  getFileBase64 = (file: any, callback: any) => {
    var reader = new FileReader();

    reader.onload = e => {
      this.setState({
        baImage: reader.result as string,
        image: file
      });
    };
    reader.readAsBinaryString(file);

    // Since FileReader is asynchronous,
    // we need to pass data back.
    // reader.onload = () => callback(reader.result);
    // // TODO: catch an error
    // reader.onerror = error => {};

    const form = new FormData();
    form.append("image", file);

    postMediaApi(this.props.companyId, form)
      .then((res: any) => {
        callback(config().rest_url + "/" + res);
      })
      .catch((res: any) => {
        console.log("error");
      });
  };

  imageUploadCallback = (file: any) =>
    new Promise((resolve, reject) =>
      this.getFileBase64(file, (data: any) => resolve({ data: { link: data } }))
    );

  changeState(value: any) {
    const contentBlock = htmlToDraft(value);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      this.onEditorStateChange(editorState);
    }
  }

  onEditorStateChange: any = (editorState: EditorState) => {
    this.setState({
      editorState
    });
  };

  checkHomePageForm = () => {
    const title =
      this.state.title == null && this.props.rowData
        ? this.props.rowData.title
        : this.state.title;
    if (title === "") return "Project Title should not be empty";
    return null;
  };

  componentDidMount() {
    if (this.state.description == null && this.props.rowData) {
      this.changeState(this.props.rowData.description);
    }
  }

  componentDidUpdate(prevprops: any) {
    if (
      this.props.rowData &&
      (prevprops.rowData
        ? this.props.rowData.description != prevprops.rowData.description
        : true)
    ) {
      this.changeState(this.props.rowData.description);
    }
  }

  updateNews = (company_id: any) => {
    // const message = this.checkHomePageForm();
    // if(message != null){
    //     return alert(message);
    // }
    const title =
      this.state.title == null && this.props.rowData
        ? this.props.rowData.title
        : this.state.title;

    const description = draftToHtml(
        convertToRaw(this.state.editorState.getCurrentContent())
    );
    const author =
      this.state.author == null && this.props.rowData
        ? this.props.rowData.author
        : this.state.author;

    const form = new FormData();
    form.append("id", this.props.rowData.id);
    form.append("title", title != null ? title : "");
    form.append("company_id", company_id);
    form.append("author", author != null ? author : "");
    form.append("description", description != null ? description : "");

    updateNewsApi(this.props.rowData.id, form)
      .then((res: any) => {
        successToast("News Updated Sucessfully");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err: any) => {
        errorToast("News Update On Error");
      });
  };

  addNews = (company_id: any) => {
    const title =
      this.state.title == null && this.props.rowData
        ? this.props.rowData.title
        : this.state.title;
    const description = draftToHtml(
        convertToRaw(this.state.editorState.getCurrentContent())
    );
    const author =
      this.state.author == null && this.props.rowData
        ? this.props.rowData.author
        : this.state.author;

    const form = new FormData();
    form.append("title", title != null ? title : "");
    form.append("company_id", company_id);
    form.append("description", description != null ? description : "");
    form.append("author", author != null ? author : "");
    postNewsApi(form)
      .then((res: any) => {
        successToast("News Created Sucessfully");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err: any) => {
        errorToast("News Create On Error");
      });
  };

  render() {
    const openStatus = this.props.open;
    const toggle = this.props.toogle;
    const rowData = this.props.rowData;
    const company = this.props.company;
    // debugger;
    return (
      <Fragment>
        <br />

        <MDBModal isOpen={openStatus} toggle={toggle} size="lg">
          <MDBModalHeader toggle={toggle}>Add News </MDBModalHeader>
          <MDBModalBody className="needs-validation" noValidate>
            <MDBContainer className="news_title">
              {/* <MDBRow> */}
                <MDBCol md="12" className="url">
                  <MDBInput
                    size="sm"
                    label="News Title"
                    required
                    outline
                    name="title"
                    onInput={this.handleInput}
                    valueDefault={rowData && rowData.title}
                  />
                </MDBCol>
              {/* </MDBRow> */}
            </MDBContainer>

            <MDBContainer className="news_author">
              {/* <MDBRow> */}
                <MDBCol md="12" className="url">
                  <MDBInput
                    size="sm"
                    label="News Author"
                    required
                    outline
                    name="author"
                    onInput={this.handleInput}
                    valueDefault={rowData && rowData.author}
                  />
                </MDBCol>
              {/* </MDBRow> */}
            </MDBContainer>

            <MDBContainer className="news_description">
              {/* <MDBRow> */}
                <MDBCol md="12" className="url">
                  <Editor
                    editorState={this.state.editorState}
                    wrapperClassName="demo-wrapper"
                    editorClassName="form-control"
                    toolbar={{
                      // image: { previewImage: true},
                      image: {
                        urlEnabled: true,
                        uploadEnabled: true,
                        alignmentEnabled: true, // Whether to display the arrange button is equivalent to text-align
                        uploadCallback: this.imageUploadCallback,
                        previewImage: true,
                        // inputAccept: 'image/*',
                        alt: { present: true, mandatory: false }
                      }
                    }}
                    onEditorStateChange={this.onEditorStateChange}
                  />
                </MDBCol>
              {/* </MDBRow> */}
            </MDBContainer>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              color="danger"
              onClick={() => {
                toggle();
              }}
            >
              Close
            </MDBBtn>
            {this.props.edit ? (
              <MDBBtn
                color="primary"
                outline
                type="submit"
                className="w-30"
                onClick={() => {
                  toggle();
                  this.updateNews(this.props.companyId);
                }}
              >
                Update
                <MDBIcon far icon="plus-square" className="ml-2" />
              </MDBBtn>
            ) : (
              <MDBBtn
                color="primary"
                outline
                type="submit"
                className="w-30"
                onClick={() => {
                  toggle();
                  this.addNews(this.props.companyId);
                }}
              >
                Add
                <MDBIcon far icon="plus-square" className="ml-2" />
              </MDBBtn>
            )}
          </MDBModalFooter>
        </MDBModal>
      </Fragment>
    );
  }
}
