import React, { useState } from 'react';
import MaterialTable, { Column } from 'material-table';
import { Button } from '@material-ui/core';
import WorkModal from '../work-modal';
import WorkGalleryModal from '../work-gallery-modal';
import TestTable from "../../../../homepage/home-data-table"
import { config } from '../../../../../../config/common';


export default function MaterialTableDemo(props : any) {

  const [addStatus, setAddStatus] = React.useState(false);

  

  const toggleAddStatus  = () => {
    setAddStatus(!addStatus);
  }
  const columns = props.columns;
  const rows = props.rows;

  const [editStatus, setEditStatus] = React.useState(false);

  const toggleEditStatus  = () => {
    setEditStatus(!editStatus);
  }

  const [galleryStatus, setGalleryStatus] = React.useState(false);
  const toggleGalleryStatus  = () => {
    setGalleryStatus(!galleryStatus);
  }

  const [rowData, setRowData] = React.useState<any>();


  const [state, setState] = React.useState<any>({
    columns: props.columns,
    data: props.rows,
  });
  
  const {company, deleteWork, workCategories, getWorkDetail, workImages} = props;

  return (
    <div>
     
    <MaterialTable
      title="Work List"
      // columns={state.columns}
      columns={[
        {
            title: "Image",
            field: "src",
            filtering: false,
            render: (rowData: any) => (
                rowData.thumbnail != null 
                ? <img
                    src={config().rest_url+'/'+rowData.thumbnail}
                    alt={`${rowData.title} image`}
                    style={{ width: 60}}
                />
                : "No Image"
                
                
                
            )
        },
        ...state.columns,
        {
          title: "Description",
          field: "description",
          filtering: false,
          render: (rowData: any) => (
              rowData.description != null 
              ? <div className="description" dangerouslySetInnerHTML={{ __html: (rowData.description ) }}/>
              : "No Image"
              
              
              
          )
      },
        
    ]}
      data={state.data}
      actions={[
        {
          icon: 'add',
          tooltip: 'Add',
          isFreeAction: true,
          onClick: (event) => {
            setAddStatus(true);
          }
        },
        rowData => ({
          icon: 'delete',
          tooltip: 'Delete',
          onClick: (event, rowData) =>{
            deleteWork(rowData)
          },
          disabled: false
        }),
        // rowData => ({
        //   icon: 'camera',
        //   tooltip: 'Add Image',
        //   onClick: (event, rowData) =>{
        //     setRowData(rowData);
        //     getWorkDetail(rowData);
        //     setGalleryStatus(true);
        //   },
        //   disabled: false
        // }),
        rowData => ({
          icon: 'edit',
          tooltip: 'Edit',
          onClick: (event, rowData) => {
            setRowData(rowData);
            setEditStatus(true);

          },
          disabled: false
        })
      ]}
      options={{
        actionsColumnIndex: -1,
        exportButton: true,
        filtering: true
      }}
      //https://material-table.com/#/docs/features/selection
    //   detailPanel={[
    //     {
    //       tooltip: 'Show Name',
    //       render: (rowData: any) => {
    //         return (
    //           <div
    //             style={{
    //               fontSize: 100,
    //               textAlign: 'center',
    //               color: 'white',
    //               backgroundColor: '#43A047',
    //             }}
    //           >
    //             <TestTable/>
    //           </div>
    //         )
    //       },
    //     }
    // ]}
    />
    {addStatus ? <div>  <WorkModal open={addStatus} toogle={toggleAddStatus} company={company} workCategories={workCategories}/></div>
    : null}
    {editStatus ? <WorkModal open={editStatus} toogle={toggleEditStatus} company={company} rowData={rowData} edit={true} workCategories={workCategories}/>
    : null}
    {galleryStatus ? <WorkGalleryModal open={galleryStatus} toogle={toggleGalleryStatus} rowData={rowData} workCategories={workCategories} workImages={workImages}/>
    : null}

    
    </div>
  );
}