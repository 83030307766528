import React, { Component } from 'react';
import { MDBCol, MDBRow, MDBBtn, MDBIcon, MDBDataTable } from 'mdbreact';
import logo from "../../assets/mdb-react.png";
import { getCompany, getAllCompany, postCompany , deleteCompany} from '../../Api/companyApi';
import SpinnerPage from '../pages/SpinnerPage';
import {successToast, errorToast} from '../../utils/toast';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import htmlToDraft from 'html-to-draftjs';
import CompanyDataTable from "./company_setting/data-table";
import CompanyModal from "./company_setting/modal";

class CompanySettingPage extends Component<any, any> {

  constructor(props: any) {
    super(props)


    this.state = {
      company: {
        id: '',
        name: "",
        description: ''
      },
      companies: [],
      loading: true,
      
    };
  }


  componentDidMount() {
    this.setState({loading: true});
    getAllCompany()
      .then((res: any) => {
        console.log("Success :: " + res);
        this.setState({ companies: res, loading: false })
      })
      .catch((err: any) => {
        alert('Error to get all  companies ' + err)
      });
  }


  render() {
    

    const data = {
      columns: [
        {
          title: 'Company Name',
          field: 'name',
        },
        {
          title: 'Company Description',
          field: 'description',
        }
      ],
    }

    return (

      this.state.loading? <SpinnerPage /> :
      <div>
        
        <div>
          <CompanyDataTable
            columns={data.columns}
            rows={this.state.companies}
          />
        </div>

      </div>
    );
  };
}


export default CompanySettingPage;