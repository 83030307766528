import React, { Fragment, useState, Component } from "react";
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBBtn, MDBIcon, MDBContainer, MDBRow, MDBCol, MDBInput } from "mdbreact";


import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import {updateCompanyApi} from "../../../../Api/api";
import { errorToast, successToast } from "../../../../utils/toast";


export default class CompanyModal extends Component<any, any>{
    
    constructor(props: any) {
        super(props)
        this.state = {
            rowData: {
                name: "",
                description: ""
            }
        }
        this.updateCompany = this.updateCompany.bind(this);
        
    }

    
    

    updateCompany = (id: string) => {
        const companyName = (this.state.rowData != undefined && this.state.rowData.name != '') ? this.state.rowData.name : (this.props.rowData != undefined )?this.props.rowData.name: '';
        const companyDescription = (this.state.rowData != undefined && this.state.rowData.description != '') ? this.state.rowData.description : (this.props.rowData != undefined )?this.props.rowData.description: '';
        const form = new FormData();
        form.append("id", id);
        form.append("name", companyName);
        form.append("description", companyDescription);
        debugger;
        updateCompanyApi(id, form)
            .then((res: any) => {
                successToast('Company Updated Sucessfully');
                setTimeout(() => {
                    window.location.reload();
                  }, 2000);
            })
            .catch((err: any) => {
                errorToast('Company Updated On Error');
            });
    }
    


    render(){
        const openStatus  = this.props.open;
        const toggle = this.props.toogle;
        const rowData = this.props.rowData;
        return (
            <Fragment>
    
                <MDBModal isOpen={openStatus} toggle={toggle} size="lg">
                    <MDBModalHeader toggle={toggle}>Add new contact </MDBModalHeader>
                    <MDBModalBody>
                        <MDBContainer className="company_name">
                            <MDBRow>
                                <MDBCol md="12" >
                                    <MDBInput 
                                        size="sm" 
                                        icon="building" 
                                        label="Company Name" 
                                        outline 
                                        className="company_name"
                                        valueDefault= {rowData && rowData.name}
                                        onChange={(event: any) => {
                                            this.setState({
                                              rowData: {
                                                ...this.state.rowData,
                                                name: event.target.value
                                              }
                          
                                            })
                                          }}
                                        />
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
    
                        <MDBContainer className="company_description">
                            <MDBRow size="6">
                                <MDBCol md="12" className="description">
                                <MDBInput 
                                        type="textarea"
                                        size="sm" 
                                        label="Company Description" 
                                        outline 
                                        className="company_description"
                                        valueDefault= {rowData && rowData.description}
                                        onChange={(event: any) => {
                                            this.setState({
                                              rowData: {
                                                ...this.state.rowData,
                                                description: event.target.value
                                              }
                          
                                            })
                                          }}
                                        />
                                </MDBCol>
                                
    
                            </MDBRow>
                        </MDBContainer>
    
    
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn 
                            color="danger" 
                            onClick={() => { 
                                toggle(); 
                            }}>
                                Close
                        </MDBBtn>
                        <MDBBtn 
                            color="primary" 
                            outline 
                            type="submit" 
                            className='w-30'
                            onClick={()=>{
                                toggle(); 
                                this.updateCompany(rowData && rowData.id)}}
                            >
                                Update
                            <MDBIcon far icon="plus-square" className="ml-2" />
                        </MDBBtn>
                        
                    </MDBModalFooter>
                </MDBModal>
    
            </Fragment>
        )
}
    
}
