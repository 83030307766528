import axios from '../Axios/index';
import React from 'react';
import { config } from '../config/common';
import {AuthState, Auth0Context, Auth, useAuth0} from '../auth/Auth'

export const postCompany = (data : any) => {
  
  return axios.request({
    url: `/api/company`,
    method:'post',
    data: data,
    headers: {'Content-Type': 'multipart/form-data'}
  })
 }

 export const getAllCompany = () => {
  return axios.request({
      url: `/api/company`,
      method:'get',
      headers: {'Content-Type': 'application/json' }
    })

  }

  export const updateCompanyApi = (id: String, data : any) => {
    
    return axios.request({
      url: `/api/company/${id}`,
      method:'post',
      data : data,
      headers: {'Content-Type': 'multipart/form-data'}
    }).then((res: any) => {
      return res.data
      }) 
  
}

    export const getCompany = (id : any) => {
      return axios.request({
        url: `/api/company/${id}`,
        method:'get',
        headers: {'Content-Type': 'application/json'}
      })
    
      }

      export const deleteCompany = (companyId: any) => {
        return axios.request({
          url: `/api/company/${companyId}`,
          method:'delete'
        })
        .then((res: any) => {
          window.location.reload();
          return res.data
          })
          
        }

      export const getContactFromCompanyId = (company_id : any) => {
        return axios.request({
          url: `/api/company/${company_id}/contact`,
          method:'get',
          headers: {'Content-Type': 'application/json'}
        })
          
        }

        
      

      export const postBanner = (company_id: string,data : any) => {
        
      return axios.request({
        url: `/api/company/${company_id}/banner`,
        method:'post',
        data: data,
        // mimeType: "",
        // crossDomain: true,
        headers: {'Content-Type': 'multipart/form-data'}
      })
      
     }





     //Products

     export const getAllProductsFromCompanyId = (company_id : any) => {
      return axios.request({
        url: `/api/product/companyId/${company_id}`,
        method:'get',
        headers: {'Content-Type': 'application/json'}
      })
        
      }

      export const getAllProductsCategoriesByCompanyId = (company_id: any) => {
        return axios.request({
          url: `api/company/${company_id}/product/categories`,
          method:'get',
          headers: {'Content-Type': 'application/json'}
        })
          
        }

      export const addProductToCompanyId = (company_id : any, data: any) => {
        
        return axios.request({
          url: `/api/company/${company_id}/product`,
          data: data,
          method:'post',
          headers: {'Content-Type': 'multipart/form-data'}
        })
        .then((res: any) => {
          return res.data
          })
          
        }

        export const updateProductToCompanyId = (id : any, data: any) => {
          
          return axios.request({
            url: `/api/product/${id}`,
            data: data,
            method:'post',
            headers: {'Content-Type': 'multipart/form-data'}
          })
          .then((res: any) => {
            return res.data
            })
            
          }

        export const deleteProductApi = (companyId:any,productId: any) => {
          return axios.request({
            url: `/api/company/${companyId}/product/${productId}`,
            method:'delete'
          })
          .then((res: any) => {
            window.location.reload();
            return res.data
            })
            
          }

          // Product Category
          export const getAllProductsCategoryFromCompanyId = (company_id : any) => {
            return axios.request({
              url: `api/company/${company_id}/product/categories`,
              method:'get',
              headers: {'Content-Type': 'application/json'}
            })
              
            }

            export const postProductCategoryImage = (company_id: string,product_category_id: string,data : any) => {
          
              return axios.request({
                url: `/api/company/${company_id}/products/categories/${product_category_id}/image`,
                method:'post',
                data: data,
                // mimeType: "",
                // crossDomain: true,
                headers: {'Content-Type': 'multipart/form-data'}
              }).then((res : any) => {
                return res.data
                })
                .catch((res: any) => {
                  alert('Error ::'+ res);
                }) 
              
             }

            export const addProductCategoryToCompanyId = (company_id : any, data: any) => {
              
              return axios.request({
                url: `api/company/${company_id}/products/categories`,
                data: data,
                method:'post',
                headers: {'Content-Type': 'multipart/form-data'}
              })
              .then((res: any) => {
                return res.data
                })
                
              }

              export const updateProductCategoryToCompanyId = (companyId: any, categoryId : any, data: any) => {
          
                return axios.request({
                  url: `/api/company/${companyId}/products/categories/${categoryId}`,
                  data: data,
                  method:'post',
                  headers: {'Content-Type': 'multipart/form-data'}
                })
                .then((res: any) => {
                  return res.data
                  })
                  
                }
              export const deleteProductCategoryApi = (companyId:any,categoryId: any) => {
                return axios.request({
                  url: `api/company/${companyId}/products/categories/${categoryId}`,
                  method:'delete'
                })
                .then((res: any) => {
                  window.location.reload();
                  return res.data
                  })
                  
                }

          // Announcement company/{company_id}/announcements
          export const getAllAnnouncementFromCompanyId = (company_id : any) => {
            return axios.request({
              url: `api/company/${company_id}/announcements`,
              method:'get',
              headers: {'Content-Type': 'application/json'}
            })
              
            }

            export const addAnnoncementToCompanyId = (company_id : any, data: any) => {
              
              return axios.request({
                url: `api/company/${company_id}/announcements`,
                data: data,
                method:'post',
                headers: {'Content-Type': 'multipart/form-data'}
              })
              .then((res: any) => {
                return res.data
                })
                
              }

              export const postAnnouncementImage = (company_id: string,announcement_id: string,data : any) => {
          
                return axios.request({
                  url: `/api/company/${company_id}/announcements/${announcement_id}/image`,
                  method:'post',
                  data: data,
                  headers: {'Content-Type': 'multipart/form-data'}
                }).then((res : any) => {
                  return res.data
                  })
                  .catch((res: any) => {
                    alert('Error ::'+ res);
                  }) 
                
               }

              export const updateAnnouncementToCompanyId = (companyId: any, announcementId : any, data: any) => {
                return axios.request({
                  url: `api/company/${companyId}/announcements/${announcementId}`,
                  data: data,
                  method:'post',
                  headers: {'Content-Type': 'multipart/form-data'}
                })
                .then((res: any) => {
                  return res.data
                  })
                  
                }

              export const deleteAnnouncementApi = (companyId:any,announcementId: any) => {
                return axios.request({
                  url: `api/company/${companyId}/announcements/${announcementId}`,
                  method:'delete'
                })
                .then((res: any) => {
                  window.location.reload();
                  return res.data
                  })
                  
                }
          //ProductImage
          export const postProductImage = (company_id: string,product_id: string,data : any) => {
          
            return axios.request({
              url: `/api/company/${company_id}/product/${product_id}`,
              method:'post',
              data: data,
              // mimeType: "",
              // crossDomain: true,
              headers: {'Content-Type': 'multipart/form-data'}
            }).then((res : any) => {
              return res.data
              })
              .catch((res: any) => {
                alert('Error ::'+ res);
              }) 
            
           }

           export const getProductDetailById = (product_id: string) => {
            return axios.request({
              url: `/api/product/${product_id}`,
              method:'get',
            }).then((res : any) => {
              return res.data
              })
              .catch((res: any) => {
                alert('Error ::'+ res);
              }) 
            
           }




          // Contacts

     export const getAllContactsFromCompanyId = (company_id : any) => {
      return axios.request({
        url: `/api/product/companyId/${company_id}`,
        method:'get',
        headers: {'Content-Type': 'application/json'}
      })
      .then((res: any) => {
        return res.data
        })
        
      }

      export const addContactToCompanyId = (company_id : any, data: any) => {
        
        return axios.request({
          url: `/api/company/${company_id}/contact`,
          data: data,
          method:'post',
          headers: {'Content-Type': 'multipart/form-data'}
        })
        .then((res: any) => {
          return res.data
          })
          
        }

        export const updateContactToCompanyId = (id : any, data: any) => {
         
          return axios.request({
            url: `/api/contact/${id}`,
            data: data,
            method:'post',
            headers: {'Content-Type': 'multipart/form-data'}
          })
          .then((res: any) => {
            return res.data
            })
            
          }

        export const deleteContactApi = (productId: any) => {
          return axios.request({
            url: `/api/contact/${productId}`,
            method:'delete'
          })
          .then((res: any) => {
            window.location.reload();
            return res.data
            })
            
          }


          export const deleteImageApi = (data : any) => {
            return axios.request({
              url: `/api/image`,
              method:'delete',
              data: data,
              headers: {'Content-Type': 'application/json'}
            })
            .then((res: any) => {
              window.location.reload();
              return res.data
              })
              
            }


  //GalleryApi

  export const getAlbumFromCompanyId = (company_id : any) => {
    return axios.request({
      url: `/api/company/${company_id}/album`,
      method:'get',
      headers: {'Content-Type': 'application/json'}
    })
      
    }

    export const addAlbumToCompanyId = (company_id : any, data: any) => {
      return axios.request({
        url: `/api/company/${company_id}/album`,
        data: data,
        method:'post',
        headers: {'Content-Type': 'multipart/form-data'}
      })
      .then((res: any) => {
        return res.data
        })
        
      }
      export const deleteAlbumApi = (companyId:any,albumId: any) => {
        return axios.request({
          url: `/api/company/${companyId}/album/${albumId}`,
          method:'delete'
        })
        .then((res: any) => {
          window.location.reload();
          return res.data
          })
          
        }

        export const updateAlbumToCompanyId = (id : any, data: any) => {
          
          return axios.request({
            url: `/api/album/${id}`,
            data: data,
            method:'post',
            headers: {'Content-Type': 'multipart/form-data'}
          })
          .then((res: any) => {
            return res.data
            })
            
          }

    // GalleryImage
    export const getGalleryDetailById = (album_id: string) => {
      return axios.request({
        url: `/api/album/${album_id}`,
        method:'get',
      }).then((res : any) => {
        return res.data
        })
        .catch((res: any) => {
          alert('Error ::'+ res);
        }) 
      
     }

     export const postAlbumImage = (company_id: string,album_id: string,data : any) => {
          
      return axios.request({
        url: `/api/company/${company_id}/album/${album_id}`,
        method:'post',
        data: data,
        headers: {'Content-Type': 'multipart/form-data'}
      }).then((res : any) => {
        return res.data
        })
        .catch((res: any) => {
          alert('Error ::'+ res);
        }) 
      
     }


     export const getAllCategory= () => {
      return axios.request({
        url: `/api/category`,
        method:'get',
        headers: {'Content-Type': 'application/json'}
      })
    
      }

      export const postCategory = (data : any) => {
        return axios.request({
          url: `/api/category`,
          method:'post',
          data: data,
          headers: {'Content-Type': 'multipart/form-data'}
        }).then((res: any) => {
          return res.data
          }) 
        
       }

       export const deleteCategoryApi = (categoryId: any) => {
        return axios.request({
          url: `/api/category/${categoryId}`,
          method:'delete'
        })
        .then((res: any) => {
          window.location.reload();
          return res.data
          })
          
        }

        export const updateMissionToCompany = (id: String, data : any) => {
    
          return axios.request({
            url: `/api/company/${id}/mission`,
            method:'post',
            data : data,
            headers: {'Content-Type': 'multipart/form-data'}
          }).then((res: any) => {
            return res.data
            }) 
        
      }
      export const updateGoalToCompany = (id: String, data : any) => {
    
        return axios.request({
          url: `/api/company/${id}/goal`,
          method:'post',
          data : data,
          headers: {'Content-Type': 'multipart/form-data'}
        }).then((res: any) => {
          return res.data
          }) 
      
    }
    export const updateHighLightToCompany = (id: String, data : any) => {
    
      return axios.request({
        url: `/api/company/${id}/highlight`,
        method:'post',
        data : data,
        headers: {'Content-Type': 'multipart/form-data'}
      }).then((res: any) => {
        return res.data
        }) 
    
  }
  export const updateObjectiveToCompany = (id: String, data : any) => {
    
    return axios.request({
      url: `/api/company/${id}/objective`,
      method:'post',
      data : data,
      headers: {'Content-Type': 'multipart/form-data'}
    }).then((res: any) => {
      return res.data
      }) 
  
}
export const updateVisionToCompany = (id: String, data : any) => {
    
  return axios.request({
    url: `/api/company/${id}/vision`,
    method:'post',
    data : data,
    headers: {'Content-Type': 'multipart/form-data'}
  }).then((res: any) => {
    return res.data
    }) 


}        

