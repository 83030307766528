import React, { Component, Fragment } from "react";
import { MDBRow, MDBCol, MDBBtn, MDBIcon, MDBInput, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBDataTable } from 'mdbreact';
import { getAllWorkCategoryByCompanyApi,deleteWorkCategoryApi } from "../../../../../Api/workCategory";

import SpinnerPage from "../../../../pages/SpinnerPage";

import { successToast, errorToast } from '../../../../../utils/toast'

import CompanyContext from "../../../homepage/context/companyContext";

import WorkCategoryDataTable from "./work-category-data-table";


interface WorkCategoryState {
  workCategory: WorkCategoryInterface,
  workCategories: [],
  modal: boolean,
  edit: boolean,

  optionActive: string,
  optionActiveId: string,
  loading: boolean
}


interface WorkCategoryInterface {
  id: string,
  title: string,
  description: string
}


export default class WorkCategoryPage extends Component<any, WorkCategoryState>{

  static contextType = CompanyContext;

  constructor(props: any) {
    super(props)
    this.state = {
      workCategory: {
        id: '',
        title: "",
        description: ""
      },
      workCategories: [],
      modal: false,
      edit: false,
      optionActive: '',
      optionActiveId: '',
      loading: false,
    };
    this.clear = this.clear.bind(this);
    this.toogleActiveOption = this.toogleActiveOption.bind(this);
    this.deleteWorkCategory = this.deleteWorkCategory.bind(this);
  }

  

componentDidMount() {
  let value = this.context;
  this.setState({loading: true});
  getAllWorkCategoryByCompanyApi(value)
        .then((res: any) => {
          this.setState({ workCategories: res, loading: false })
        })
        .catch((err: any) => {
          alert('Error to get work categories ' + err)
        });
}
    
deleteWorkCategory = (rowdata: any) => {
  if (window.confirm("Are you sure you want to delete this work category?")) {
    deleteWorkCategoryApi(rowdata.id)
          .then((res: any) => {
            successToast('Company Work Category Deleted Sucessfully');
              setTimeout(() => {
                  window.location.reload();
                }, 2000);
          })
          .catch((err: any) => {
            alert('Error to delete a company work category' + err)
          });
  }

}


  toogleActiveOption = (id: string) => {
    this.setState({
      optionActive: this.state.optionActive == '' ? 'active' : '',
      optionActiveId: id
    })

  }



  clear() {
    this.setState({
      workCategory: {
        ...this.state.workCategory,
        title: "",
        description: ""
      }
      

    });
  }




  render() {
    const data = {
      columns: [
       
        {
          title: 'Title',
          field: 'title',
        },
        {
          title: 'Description',
          field: 'description',
        },
      ],
    }

    const workCategories = this.state.workCategories;
    const company = this.props.company && this.props.company[0];

    return (
      

      this.state.loading ? <SpinnerPage /> :


        <div>

          <div className="work-category-table">
            <WorkCategoryDataTable
              columns={data.columns}
              rows={workCategories}
              company={company}
              deleteWorkCategory={this.deleteWorkCategory}
            />

          </div>

        </div>
    );
  };
}