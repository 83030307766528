import axios from '../Axios/index';

export const postAboutApi = async(data: any) => {
    return await axios.request({
        url: `/api/about`,
        method: 'post',
        data: data,
        headers: { 'Content-Type': 'multipart/form-data' }
    }).then((res: any) => {
        return res.data
    })
}

export const getAllAboutsApi = async () => {
    return await axios.request({
        url: `/api/about`,
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    }).then((res: any) => {
        return res.data
    })
}
export const getAllAboutsByCompanyApi = async (company_id: String) => {
    return await axios.request({
        url: `/api/about/company/${company_id}`,
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    }).then((res: any) => {
        return res.data
    })
}
export const updateAboutApi = async (id: String, data: any) => {

    return await axios.request({
        url: `/api/about/${id}`,
        method: 'post',
        data: data,
        headers: {'Content-Type': 'multipart/form-data'}
    }).then((res: any) => {
        return res.data
    })

}

export const getAboutApi = async(id: any) => {
    return await axios.request({
        url: `/api/about/${id}`,
        method: 'get',
        headers: { 'Content-Type': 'application/json' }
    }).then((res: any) => {
        return res.data
    })

}

export const deleteAboutApi = async (aboutId: any) => {
    return await axios.request({
        url: `/api/about/${aboutId}`,
        method: 'delete'
    }).then((res: any) => {
        return res.data
    })

}