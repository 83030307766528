import React, { Component, Fragment } from "react";
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBDataTable
} from "mdbreact";
import {
  getContactFromCompanyId,
  addContactToCompanyId,
  deleteContactApi,
  updateContactToCompanyId
} from "../../../../Api/api";

import {
  getAllCareersByCompanyApi,
  deleteCareerApi
} from "../../../../Api/careerApi";
import SpinnerPage from "../../../pages/SpinnerPage";

import { successToast, errorToast } from "../../../../utils/toast";

import HomeDataTable from "./home-data-table";

// import CompanyContext from "../..//context/companyContext";

interface HomeInterfaceProps {
  id: string;
  project_title: string;
  project_description: string;
  image_url: string;
  video_url: string;
}

interface HomeInterfaceState {
  home: HomeInterface;
  careers: [];
  modal: boolean;
  edit: boolean;

  optionActive: string;
  optionActiveId: string;
  loading: boolean;
}

interface HomeInterface {
  id: string;
  project_title: string;
  project_description: string;
  image_url: string;
  video_url: string;
}

export default class CareerTab extends Component<any, HomeInterfaceState> {
  // static contextType = CompanyContext;

  constructor(props: any) {
    super(props);
    this.state = {
      home: {
        id: "",
        project_title: "",
        project_description: "",
        image_url: "",
        video_url: ""
      },
      careers: [],
      modal: false,
      edit: false,
      optionActive: "",
      optionActiveId: "",
      loading: false
    };
    this.clear = this.clear.bind(this);
    this.toogleActiveOption = this.toogleActiveOption.bind(this);
    this.deleteCareer = this.deleteCareer.bind(this);
  }

  componentDidMount() {

    this.setState({ loading: true });
    getAllCareersByCompanyApi(this.props.companyId)
      .then((res: any) => {
        console.log("Success :: ", res);
        this.setState({ careers: res, loading: false });
      })
      .catch((err: any) => {
        alert("Error to create a company" + err);
      });
  }

  deleteCareer = (rowdata: any) => {
    if (window.confirm("Are you sure you want to delete this career?")) {
      deleteCareerApi(rowdata.id)
        .then((res: any) => {
          successToast("Career Deleted Sucessfully");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch((err: any) => {
          alert("Error to delete a career" + err);
        });
    }
  };

  toogleActiveOption = (id: string) => {
    this.setState({
      optionActive: this.state.optionActive == "" ? "active" : "",
      optionActiveId: id
    });
  };

  clear() {
    this.setState({
      home: {
        ...this.state.home,
        project_title: "",
        project_description: "",
        image_url: "",
        video_url: ""
      }
    });
  }

  render() {
    const data = {
      columns: [
        {
          title: "Date",
          field: "created_at"
        },
        {
          title: "Career Title",
          field: "title"
        },
        {
          title: "Career Description",
          field: "description"
        }
      ]
    };

    const careers = this.state.careers;
    const companyId = this.props.companyId ;

    return this.state.loading ? (
      <SpinnerPage />
    ) : (
      <div>
        <div className="contact-table">
          <HomeDataTable
            columns={data.columns}
            rows={careers}
            deleteCareer={this.deleteCareer}
            companyId={companyId}
          />
        </div>
      </div>
    );
  }
}
